import styled from "styled-components";
import { Grid, Crossword, Clue, Position } from "../../core";
import { buildArray } from "../../utils";
import { Theme } from "../themes";

interface CrosswordUIProps {
    crossword: Crossword
    currentClue?: Clue | undefined
    setCurrentClue?: (currentClue: Clue) => void;
    theme: Theme;
    currentGuess?: string;
    reference?: Crossword;
}

export const CrosswordUI: React.FC<CrosswordUIProps> = ({ 
    crossword, 
    currentClue, 
    setCurrentClue,
    theme,
    currentGuess,
    reference,
}) => {
    const clues = crossword.layout.getClues();
    const clueGrid = new Grid<Clue[] | undefined>(crossword.size, undefined);
    for (const clue of clues) {
        const current = clueGrid.get(clue.start) || [];
        clueGrid.set(clue.start, [...current, clue]);
    }

    const clickPosition = (position: Position) => {
        if (setCurrentClue === undefined) {
            return;
        }
        const [clue1, clue2] = crossword.layout.cluesAt(position);
        let clue;
        if (currentClue?.id === clue1.id) {
            clue = clue2;
        } else if (currentClue?.id === clue2.id) {
            clue = clue1;
        } else {
            clue = currentClue?.direction === clue1.direction   
                ? clue1
                : clue2;
        }
        setCurrentClue(clue);
    }

    return <CrosswordTable>
        <tbody>
            {buildArray(crossword.size, (row) => {
                return <tr key={row}>
                    {buildArray(crossword.size, (column) => {
                        const position = new Position(row, column);
                        const clue = (clueGrid.get(position) || [])[0];
                        const indexInClue = currentClue?.positions
                            .findIndex((p) => p.isEqual(position)) ?? -1;
                        const currentGuessLetter = (currentGuess || "")[indexInClue];
                        const showLetter = currentGuessLetter || reference?.get(position);
                        const letterRaw = crossword.get(position);
                        const letter = typeof letterRaw === "string" ? letterRaw : "";
                        const highlighted = (
                            currentClue === undefined
                            || currentClue.hasPosition(position)
                        );
                        const filled = Crossword.isFilled(crossword.get(position));
                        return <CrosswordTd 
                            correct={typeof letterRaw === "string"}
                            highlighted={highlighted}
                            filled={filled}
                            key={column}
                            theme={theme}
                            onClick={() => clickPosition(position)}
                        >
                            {clue ? <ClueNumber theme={theme}>
                                {clue.number}
                            </ClueNumber> : null}
                            <CrosswordLetter>
                                {letter || showLetter}
                            </CrosswordLetter>
                        </CrosswordTd>;
                    })}
                </tr>;
            })}
        </tbody>
    </CrosswordTable>;
}

const ClueNumber = styled.span<{
    theme: Theme;
}>`
    position: absolute; 
    top: 0px;
    left: 2px;
    font-size: 10px;
    font-weight: normal;
    ${({ theme }) => `color: ${theme.emptyForeground};`}
`;

const CrosswordTable = styled.table`
    border-collapse: collapse;
    margin: 20px;
    user-select: none;
`;

const CrosswordTd = styled.td<{ 
    correct: boolean;
    highlighted: boolean;
    filled: boolean;
    theme: Theme;
}>`
    position: relative;
    width: 32px;
    height: 30px;
    min-width: 32px;
    min-height: 30px;
    max-width: 32px;
    max-height: 30px;
    padding: 3px 2px;
    text-align: center;
    font-weight: bold;
    ${({ correct, highlighted, filled, theme }) => 
        (filled 
        ? `background-color: ${theme.filled};` 
        : correct 
        ? ( 
            highlighted
            ? `background-color: ${theme.hit}; color: white;` 
            : `background-color: ${
                theme.hitMuted
            }; color: ${
                theme.hitMutedForeground
            };` 
        )
        : ( 
            highlighted
            ? `background-color: ${theme.emptyHighlight}; 
                color: ${theme.foregroundMuted};` 
            : `background-color: ${theme.empty}; color: ${theme.emptyHighlight};` 
        ))
        + `
            border: 1px solid ${theme.borderDark};
        `
    }
`;

const CrosswordLetter = styled.span`
    text-transform: uppercase;
    margin: 10px;
`;