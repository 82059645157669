import styled from "styled-components"
import { ClueDirection, Lexicon, Crossword, Wordle } from "../../core"
import { WordleUI } from "../Wordle";
import domToImage from 'dom-to-image';
import { useRef, useState } from "react";
import { useEffect } from "react";
import { CrosswordUI } from "../CrosswordUI";
import { Theme } from "../themes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faArrowUpFromBracket, 
    faRotate,
} from '@fortawesome/free-solid-svg-icons'
import { Countdown } from "../Countdown";
import { formatStopwatch, tomorrowLocal } from "../../utils";
import { trackShareSummary } from "../../analytics";
import { Definition } from "../Definition";

interface EndgameProps {
    result: boolean;
    wordles: Map<string, Wordle>;
    grid: Crossword;
    maxGuesses: number;
    crossword: Crossword;
    lexicon: Lexicon;
    crosswordleNumber: number | undefined;
    theme: Theme;
    startNewPracticeGame: () => void;
    practiceNumber: number | null;
    playTime: number;
}

const DOM_SCALE = 6;

export const Endgame: React.FC<EndgameProps> = ({ 
    result, 
    crossword,
    wordles, 
    maxGuesses,
    lexicon,
    grid,
    crosswordleNumber,
    theme,
    startNewPracticeGame,
    practiceNumber,
    playTime
}) => {
    const summaryRef = useRef<HTMLDivElement>(null);
    const [summaryImage, setSummaryImage] = useState("");
    const [sumamryBlob, setSummaryBlob] = useState<Blob>();
    const crosswordRef = useRef<HTMLDivElement>(null);
    const [crosswordImage, setCrosswordImage] = useState("");

    const isDaily = crosswordleNumber !== undefined;

    useEffect(() => {
        setTimeout(() => {
            const node = summaryRef.current;
            if (node) {
                const options = {
                    width: node.clientWidth * DOM_SCALE,
                    height: node.clientHeight * DOM_SCALE,
                    bgcolor: theme.background,
                    style: {
                        transform: `scale(${DOM_SCALE})`,
                        transformOrigin: 'top left'
                    }
                };
                domToImage.toPng(node, options).then(setSummaryImage);
                domToImage.toBlob(node, options).then(setSummaryBlob);
            }
        });
    }, [summaryRef, theme]);

    useEffect(() => {
        let canceled = false;
        setTimeout(() => {
            if (canceled) {
                return;
            }
            const node = crosswordRef.current;
            if (node) {
                domToImage.toPng(node, {
                    width: node.clientWidth * DOM_SCALE,
                    height: node.clientHeight * DOM_SCALE,
                    bgcolor: theme.background,
                    style: {
                        transform: `scale(${DOM_SCALE})`,
                        transformOrigin: 'top left'
                    }
                }).then(setCrosswordImage);
            }
        });
        return () => {
            canceled = true;
        }
    }, [crosswordRef, theme]);

    const getFileName = (label: string) => {
        return `crosswordle_${crosswordleNumber || "practice"}_${label}.png`;
    }

    const onSaveImage = (image: string, label: string) => {
        var a = document.createElement('a');
        a.href = image;
        a.download = getFileName(label);
        document.body.appendChild(a);
        a.click();  
        document.body.removeChild(a);
    }

    const numGuesses = [...wordles.values()]
        .map((wordle) => wordle.guesses.length)
        .reduce((a, b) => a + b, 0);

    const totalMaxGuesses = wordles.size * maxGuesses;

    const title = (
        (crosswordleNumber 
            ? `Crosswordle ${crosswordleNumber} (${formatStopwatch(playTime)})`
            : ` Practice ${(practiceNumber ?? 0) + 1} (${formatStopwatch(playTime)})`)
        + (result ? ` ${numGuesses} / ${totalMaxGuesses}` : "")
    );

    const fullTitle = (
        (result ? "You win! " : "You lose! ")
        + title
    );

    const shareData = sumamryBlob ? {
        files: [
            new File([sumamryBlob], getFileName("summary"), {
                type: sumamryBlob.type,
            }),
        ],
        title: title,
        text: title,
        url: "https://crosswordle.xyz"
    } : undefined;

    const canShare = () => {
        try {
            return navigator.canShare !== undefined && shareData && navigator.canShare(shareData);
        } catch (error) {
            return false;
        }
    }

    const shareEnabled = canShare();

    const onShare = () => {
        try {
            if (navigator.canShare(shareData)) {
                navigator.share(shareData)
                    .then(() => {})
                    .catch((error) => {
                        console.log(error);
                    });
                trackShareSummary({});
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    return <EndgameDiv theme={theme}>
        <BoardContainer ref={crosswordRef}>
            <CrosswordUI 
                crossword={grid} 
                theme={theme}
                reference={crossword}
            />
        </BoardContainer>
        <ResultDiv ref={summaryRef}>
            <TitleP>
                { fullTitle }
                <span>
                    { shareEnabled && <IconButton 
                        icon={faArrowUpFromBracket} 
                        color={theme.hit} 
                        onClick={onShare}
                        size="lg"
                    /> }
                    { !isDaily && <IconButton 
                        icon={faRotate} 
                        color={theme.hit} 
                        onClick={startNewPracticeGame}
                        size="lg"
                    /> }
                </span>
            </TitleP>
            <Hr theme={theme} />
            <CluesSection>
                { crossword.layout
                    .getClues()
                    .map((clue) => {
                        const wordle = wordles.get(clue.id)!;
                        const directionSymbol = 
                            clue.direction === ClueDirection.Across 
                            ? "→" 
                            : "↓";
                        const word = crossword.patternFor(clue);
                        const crossWordles = clue.positions.map(
                            (position) => wordles.get(
                                crossword.layout.otherClue(clue, position).id
                            )!
                        );
                        const lettersFoundHere = clue.positions.map(
                            (position, index) => {
                                const indexSolved = wordle.guesses.findIndex((guess) => guess[index] === word[index]);
                                const orderFoundHere = (indexSolved === -1) ? undefined : wordle.guessOrder[indexSolved];
                                const crossWordle = crossWordles[index];
                                const indexForCross = clue.direction === ClueDirection.Across ? position.row : position.column;
                                const indexSolvedCross = crossWordle.guesses.findIndex((guess) => guess[indexForCross] === word[index]);
                                const orderFoundCross = (indexSolvedCross === -1) ? undefined : crossWordle.guessOrder[indexSolvedCross];
                                return orderFoundHere !== undefined && (orderFoundCross === undefined || orderFoundHere < orderFoundCross);
                            }
                        );
                        return <ClueDiv key={clue.id}>
                            <NumberSection>
                                <div>{clue.number}</div>
                                <div>{directionSymbol}</div>
                            </NumberSection>
                            <WordleUI 
                                word={word}
                                answers={wordle.guesses}
                                guessCount={maxGuesses}
                                currentAnswer=""
                                highlighed={true}
                                lexicon={lexicon}
                                redacted={true}
                                correctElsewhere={
                                    grid.patternFor(clue) === word
                                }
                                lettersFoundHere={lettersFoundHere}
                                theme={theme}
                                guessIncorrect={false}
                            />
                        </ClueDiv>;
                    })
                }
            </CluesSection>
            {isDaily && <p>
                Next puzzle in <Countdown end={tomorrowLocal()} />
            </p> }
        </ResultDiv>
        <ImagesBox>
            <TitleP>Images</TitleP>
            <Hr theme={theme} />
            { summaryImage && 
                <SaveImage 
                    theme={theme}
                    height="100px" 
                    src={summaryImage} 
                    alt="Crosswordle results summary" 
                    onClick={() => onSaveImage(summaryImage, "summary")}
                /> 
            }
            { crosswordImage && 
                <SaveImage 
                    theme={theme}
                    height="100px" 
                    src={crosswordImage} 
                    alt="Crosswordle final board" 
                    onClick={() => onSaveImage(crosswordImage, "grid")}
                /> 
            }
        </ImagesBox>
        <DefinitionsBox>
            <TitleP>Definitions</TitleP>
            <Hr theme={theme} />
            <ActualDefinitions>
                { crossword.layout.getClues().map((clue) => {
                    const word = crossword.patternFor(clue);
                    const directionSymbol = 
                        clue.direction === ClueDirection.Across 
                        ? "→" 
                        : "↓";
                    return <DefinitionRow key={word}>
                        <PadNumber>
                            <NumberSection>
                                <div>{clue.number}</div>
                                <div>{directionSymbol}</div>
                            </NumberSection>
                        </PadNumber>
                        <Definition word={word} theme={theme}   />
                    </DefinitionRow>
                })}
            </ActualDefinitions>
        </DefinitionsBox>
    </EndgameDiv>
}

const EndgameDiv = styled.div<{
    theme: Theme;
}>`
    margin-top: 50px;
    max-width: 370px;
    width: 100%;
    align-items: center;
    flex-direction: column;
    display: flex;
    width: 100%;
    overflow-x: hidden;
    ${({ theme }) => `color: ${theme.foreground}`}
`;

const ResultDiv = styled.div`
    width: 100%;
    padding: 20px;
    align-items: center;
    flex-direction: column;
`;

const Hr = styled.hr<{
    theme: Theme;
}>`
    border: none;
    width: 100%;
    ${({ theme }) => `border-bottom: 1px solid ${theme.borderLight}`}
`;

const ClueDiv = styled.div`
    margin: 10px;
    display: inline-block;
`;

const CluesSection = styled.div`
    width: 100%;
    overflow: hidden;
`;

const NumberSection = styled.div`
    width: 12px;
    font-size: 12px;
    float: left;
    margin-right: 5px;
`;

const ImagesBox = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;

const DefinitionsBox = styled.div`
    width: 100%;
    margin-bottom: 20px;
    overflow-x: hidden;
    padding-bottom: env(safe-area-inset-bottom);
`;

const SaveImage = styled.img<{
    theme: Theme;
}>`
    border-radius: 5px;
    
    margin: 5px;
    cursor: pointer;

    ${({ theme }) => `border: 1px solid ${theme.borderDark};`}
`;

const BoardContainer = styled.div`
    display: flex;
    float: left;
`;

const TitleP = styled.p`
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;
`;

const IconButton = styled(FontAwesomeIcon)`
    margin-left: 10px;
    cursor: pointer;
`;

const DefinitionRow = styled.div`
    padding: 10px;
    display: flex;
    gap: 10px;
    max-width: 100%;
`;

const ActualDefinitions = styled.div`
    max-width: 100%;
`;

const PadNumber = styled.div`
    padding: 3px 0px;
`;