import { faBullseye, faCalendarDay, faTurnUp, faDeleteLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { KeyColorBase, LEXICON_PERMISSIVE } from "../../core"
import { KeyUI } from "../KeyUI"
import { Theme } from "../themes"
import { WordleUI } from "../Wordle"
import { faInstagram, faReddit, faFacebook } from "@fortawesome/free-brands-svg-icons"

interface InfoProps {
    theme: Theme;
}

export const Info: React.FC<InfoProps> = ({ theme }) => {
    return <InfoDiv theme={theme}>
        <p>Solve the Crosswordle by guessing the Wordle for each clue.</p>

        <p>Each guess must be a valid word. Press Enter or <FontAwesomeIcon icon={faTurnUp} /> to submit and press Backspace or <FontAwesomeIcon icon={faDeleteLeft} /> to delete an invalid guess.</p>

        <p>After each guess, the color of the clue tiles will change to show how close your guess was to the word, and the crossword will fill in with any correctly-placed letters.</p>

        <p>You win when the entire crossword is filled in. You lose if you run out of guesses for a tile in both the across and down directions.</p>

        <p>To change which Wordle you're solving, tap or click on a space in the crossword. Clicking the same space a second time will switch between the across and down clue for that space.</p>

        <p>A new Crosswordle will be available each day, and you can also click <FontAwesomeIcon 
            icon={faBullseye} 
            color={theme.miss}
        /> to practice on a random puzzle! Press <FontAwesomeIcon 
            icon={faCalendarDay} 
            color={theme.hit} 
        /> to go back to the daily puzzle.</p>

        <h3>Examples</h3>

        <Hr theme={theme} />

        <WordleUI 
            word="crane"
            guessCount={1}
            answers={["train"]}
            currentAnswer=""
            lexicon={LEXICON_PERMISSIVE}
            highlighed={true}
            theme={theme}
            guessIncorrect={false}
        />

        <p>
            The letters <b>R</b> and <b>A</b> are in the word and in the correct spot.
        </p>

        <p>
            The letter <b>N</b> is in the word but in the wrong spot.
        </p>

        <p>The letters <b>T</b> and <b>I</b> are not in the word in any spot.</p>

        <WordleUI 
            word="greed"
            guessCount={1}
            answers={["three"]}
            currentAnswer=""
            lexicon={LEXICON_PERMISSIVE}
            highlighed={true}
            theme={theme}
            guessIncorrect={false}
        />
        
        <p>
            The first <b>E</b> is in the word and in the correct spot.
        </p>

        <p>
            The letter <b>R</b> and the second <b>E</b> are in the word but in the wrong spot.
        </p>

        <p>The letters <b>T</b> and <b>H</b> are not in the word in any spot.</p>

        <WordleUI 
            word="crane"
            guessCount={1}
            answers={[]}
            currentAnswer="yeets"
            lexicon={LEXICON_PERMISSIVE}
            highlighed={true}
            theme={theme}
            guessIncorrect={true}
        />

        <p>The word <b>YEETS</b> is not in the Crosswordle dictionary. You can press Backspace or the <FontAwesomeIcon icon={faDeleteLeft} /> button to edit the word.</p>

        <h3>Keyboard</h3>

        <Hr theme={theme} />

        <p>Like in Wordle, the keys on the virtual keyboard change color based on the available information.</p>

        <table>
            <tbody>
                <tr>
                    <td>
                        <MyKeyUI 
                            colorCode={{ base: KeyColorBase.Blank, crossInvalid: false }} 
                            wide={false} 
                            theme={theme} 
                            showCrossInfo={false}
                        >A</MyKeyUI>
                    </td>
                    <td>
                        <i>might</i> appear within the selected word.
                    </td>
                </tr>
                <tr>
                    <td>
                        <MyKeyUI 
                            colorCode={{ base: KeyColorBase.Miss, crossInvalid: false }} 
                            wide={false} 
                            theme={theme} 
                            showCrossInfo={false}
                        >B</MyKeyUI>
                    </td>
                    <td>
                        does not appear in the current word.
                    </td>
                </tr>
                <tr>
                    <td>
                        <MyKeyUI 
                            colorCode={{ 
                                base: KeyColorBase.NearMiss, crossInvalid: false 
                            }} 
                            wide={false} 
                            theme={theme} 
                            showCrossInfo={false}
                        >C</MyKeyUI>
                    </td>
                    <td>
                        appears in the word <i>somewhere</i>.
                    </td>
                </tr>
                <tr>
                    <td>
                        <MyKeyUI 
                            colorCode={{ 
                                base: KeyColorBase.Hit, crossInvalid: false 
                            }} 
                            wide={false} 
                            theme={theme} 
                            showCrossInfo={false}
                        >D</MyKeyUI>
                    </td>
                    <td>
                        appears in the word, and a previous guess placed it in the correct position.
                    </td>
                </tr>
            </tbody>
        </table>

        <p>With the <b>Advanced keyboard</b> enabled, these keys may show up as well (based on letters known <i>not</i> to be in words that cross the current word):</p>

        <table>
            <tbody>
                <tr>
                    <td>
                        <MyKeyUI 
                            colorCode={{ base: KeyColorBase.Blank, crossInvalid: true }} 
                            wide={false} 
                            theme={theme} 
                            showCrossInfo={true}
                        >E</MyKeyUI>
                    </td>
                    <td>
                        <i>might</i> appear within the word, but not as the next letter.
                    </td>
                </tr>
                <tr>
                    <td>
                        <MyKeyUI 
                            colorCode={{ 
                                base: KeyColorBase.NearMiss, crossInvalid: true 
                            }} 
                            wide={false} 
                            theme={theme} 
                            showCrossInfo={true}
                        >F</MyKeyUI>
                    </td>
                    <td>
                        appears in the word <i>somewhere</i>, but not as the next letter.
                    </td>
                </tr>
                <tr>
                    <td>
                        <MyKeyUI 
                            colorCode={{ 
                                base: KeyColorBase.Hit, crossInvalid: true 
                            }} 
                            wide={false} 
                            theme={theme} 
                            showCrossInfo={true}
                        >G</MyKeyUI>
                    </td>
                    <td>
                        appears in the word, and a previous guess placed it in the correct position. It may appear another time, but not as the next letter.
                    </td>
                </tr>
            </tbody>
        </table>

        <h3>Socials</h3>

        <Hr theme={theme} />

        <SocialsDiv>
            <IconLink href="https://www.reddit.com/r/crosswordle" theme={theme}>
                <FontAwesomeIcon icon={faReddit} size="2x" />
            </IconLink>
            <IconLink href="https://instagram.com/crosswordle.xyz" theme={theme}>
                <FontAwesomeIcon icon={faInstagram} size="2x" />
            </IconLink>
            <IconLink href="https://www.facebook.com/Crosswordle-105296898775677" theme={theme}>
                <FontAwesomeIcon icon={faFacebook} size="2x" />
            </IconLink>
        </SocialsDiv>
    </InfoDiv>
}

const InfoDiv = styled.div<{
    theme: Theme;
}>`
    margin-top: 50px;
    max-width: 700px;
    padding: 20px;
    margin-bottom: 50px;
    ${({ theme }) => `color: ${theme.foreground};`}
`;

const Hr = styled.hr<{
    theme: Theme;
}>`
    border: none;
    ${({ theme }) => `border-bottom: 1px solid ${theme.borderLight}`}
`;

const MyKeyUI = styled(KeyUI)`
    width: 1.5em;
    max-width: 1.5em;
    margin: 5px;
`;

const SocialsDiv = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    padding-bottom: env(safe-area-inset-bottom);
`;

const IconLink = styled.a<{
    theme: Theme;
}>`
    ${({ theme }) => `
        color: ${theme.foreground};

        &:visited {
            color: ${theme.foreground};
        }
    `}
`;