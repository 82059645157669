import { Trie } from "./base";
import { WORDLIST, WORDLIST_PERMISSIVE } from "./wordlist";

export const LEXICON = new Trie();
export const LEXICON_PERMISSIVE = new Trie();

WORDLIST.forEach((word) => {
    LEXICON.add(word);
    LEXICON_PERMISSIVE.add(word);
});

WORDLIST_PERMISSIVE.forEach((word) => LEXICON_PERMISSIVE.add(word));