import { buildArray } from "../utils";
import md5 from "md5";

export class Position {
    constructor(
        public readonly row: number, 
        public readonly column: number
    ) {}

    get leftNeighbor(): Position {
        return new Position(this.row, this.column - 1);
    }

    get upperNeighbor(): Position {
        return new Position(this.row - 1, this.column);
    }
    get rightNeighbor(): Position {
        return new Position(this.row, this.column + 1);
    }

    get lowerNeighbor(): Position {
        return new Position(this.row + 1, this.column);
    }

    isEqual(other: Position): boolean {
        return this.column === other.column && this.row === other.row;
    }
}

export enum ClueDirection {
    Across = "a",
    Down = "d",
}

export enum WordleScore {
    Hit = 1,
    Miss = 2,
    NearMiss = 3,
    Invalid = 4,
}

export enum KeyColorBase {
    Hit = 'hit',
    Miss = "miss",
    NearMiss = "near-miss",
    Blank = "blank",
}

export interface KeyColor {
    base: 'hit' | "miss" | "near-miss" | "blank",
    crossInvalid: boolean,
}

export class Clue {
    constructor(
        public readonly number: number,
        public readonly direction: ClueDirection,
        public readonly start: Position,
        public readonly positions: Position[],
    ) {}

    get id() {
        return `${this.number}${this.direction}`;
    }

    get wordLength() {
        return this.positions.length;
    }

    hasPosition(position: Position) {
        return this.positions.some((cluePosition) => cluePosition.isEqual(position))
    }

    static isAcross(clue: Clue) {
        return clue.direction === ClueDirection.Across;
    }

    static isDown(clue: Clue) {
        return clue.direction === ClueDirection.Down;
    }
}

export class Grid<T> {
    private raw: T[][];

    constructor(size: number, value: T) {
        const raw: T[][] = [];
        for (let row = 0; row < size; row++) {
            const rowRaw: T[] = [];
            for (let column = 0; column < size; column++) {
                rowRaw.push(value);
            }
            raw.push(rowRaw);
        }
        this.raw = raw;
    }

    get size(): number {
        return this.raw.length;
    }

    get(position: Position) {
        return this.raw[position.row][position.column];
    }

    set(position: Position, value: T) {
        this.raw[position.row][position.column] = value;
    }

    has(position: Position) {
        return (
            position.row >= 0 && 
            position.row < this.size && 
            position.column >= 0 && 
            position.column < this.size
        );
    }

    forEach(func: (position: Position, value: T) => void) {
        for (let row = 0; row < this.size; row++) {
            for (let column = 0; column < this.size; column++) {
                func(new Position(row, column), this.raw[row][column]);
            }
        }
    }

    map<O>(func: (position: Position, value: T) => O): O[] {
        const result: O[] = [];
        for (let row = 0; row < this.size; row++) {
            for (let column = 0; column < this.size; column++) {
                result.push(func(new Position(row, column), this.raw[row][column]));
            }
        }
        return result;
    }
}

const EMPTY = Symbol("empty");
type Empty = typeof EMPTY;

const FILLED = Symbol("filled");
type Filled = typeof FILLED;

export class Answer {
    constructor(public readonly clue: Clue, public readonly word: string) {}
}

export class Layout extends Grid<Filled | undefined> {
    constructor(size: number, filledPositions: Position[]) {
        super(size, undefined);
        for (const filledPosition of filledPositions) {
            this.set(filledPosition, FILLED);
        }
    }

    public getClues(): Clue[] {
        const clues: Clue[] = [];
        let currentNumber = 1;
        this.forEach((position) => {
            if (this.get(position) === FILLED) {
                return;
            }
            const left = position.leftNeighbor;
            const up = position.upperNeighbor;
            let usedNumber = false;
            if (!this.has(left) || this.get(left) === FILLED) {
                const positions = [];
                let current = position;
                while (this.has(current) && this.get(current) !== FILLED) {
                    positions.push(current);
                    current = current.rightNeighbor;
                }
                clues.push(new Clue(
                    currentNumber, 
                    ClueDirection.Across, 
                    position, 
                    positions
                ));
                usedNumber = true;
            }
            if (!this.has(up) || this.get(up) === FILLED) {
                const positions = [];
                let current = position;
                while (this.has(current) && this.get(current) !== FILLED) {
                    positions.push(current);
                    current = current.lowerNeighbor;
                }
                clues.push(new Clue(
                    currentNumber, 
                    ClueDirection.Down, 
                    position, 
                    positions
                ));
                usedNumber = true;
            }
            if (usedNumber) {
                currentNumber++;
            }
        });
        return clues;
    }

    otherClue(clue: Clue, position: Position): Clue {
        const [clue1, clue2] = this.cluesAt(position);
        return clue1.id === clue.id ? clue2 : clue1;
    }

    cluesAt(position: Position): [Clue, Clue] {
        const clues = this.getClues().filter((clue) => clue.hasPosition(position));
        if (clues.length !== 2) {
            throw new Error("Expected there to be exactly two clues for each position.");
        }
        const [clue1, clue2] = clues;
        return [clue1, clue2];
    }

    isEqual(other: Layout) {
        let equal = true;
        this.forEach((position, value) => {
            if (!other.has(position) || other.get(position) !== value) {
                equal = false;
            };
        }); 
        return equal;
    }
}

export interface WordleState  { 
    guess: string;
    guesses: string[]; 
}

export interface WordlesState {
    [clueId: string]: WordleState
}

export class Crossword extends Grid<Filled | Empty | string> {
    constructor(public readonly layout: Layout) {
        super(layout.size, EMPTY);
        this.layout.forEach((position, maybeFilled) => {
            if (maybeFilled === FILLED) {
                this.set(position, FILLED);
            }
        })
    }

    patternFor(clue: Clue) {
        return clue.positions.map((position) => {
            const at = this.get(position);
            if (at === EMPTY) {
                return "."
            } else if (at === FILLED) {
                throw new Error("Bad clue positions");
            } else {
                return at;
            }
        }).join("");
    }

    clone() {
        const newPuzzle = new Crossword(this.layout);
        this.forEach((position, value) => {
            newPuzzle.set(position, value);
        });
        return newPuzzle;
    }

    fillAnswer(clue: Clue, answer: string) {
        clue.positions.forEach((position, index) => {
            this.set(position, answer[index]);
        })
    }

    unset(position: Position) {
        this.set(position, EMPTY);
    }

    toStorageString(): string {
        let out = "";
        this.forEach((_, value) => {
            if (value === FILLED) {
                out += "X";
            } else if (value === EMPTY) {
                out += "_";
            } else {
                out += value;
            }
        });
        return out;
    }

    static fromStorageString(storageString: string): Crossword {
        const dimension = Math.sqrt(storageString.length);
        if (dimension !== Math.floor(dimension)) {
            throw new Error("Crossword must be square.");
        }
        const json = buildArray(dimension, (row) => {
            return buildArray(dimension, (col) => {
                const storage = storageString[row * dimension + col];
                if (storage === "X") {
                    return null;
                }
                return storage;
            });
        });
        return Crossword.fromJSON(json);
    }

    toString(): string {
        let out = "";

        for (let row = 0; row < this.size; row++) {
            for (let column = 0; column < this.size; column++) {
                const value = this.get(new Position(row, column));

                if (value === FILLED) {
                    out += "X";
                } else if (value === EMPTY) {
                    out += "_";
                } else {
                    out += value;
                }
            }
            out += "\n"
        }
        return out;
    }

    toJSON() {
        let out = [];

        for (let row = 0; row < this.size; row++) {
            const theRow = [];
            for (let column = 0; column < this.size; column++) {
                const value = this.get(new Position(row, column));

                if (value === FILLED) {
                    theRow.push(null);
                } else if (value === EMPTY) {
                    theRow.push(" ");
                } else {
                    theRow.push(value);
                }
            }
            out.push(theRow);
        }
        return out;
    }

    static fromJSON(json: (string | null)[][]): Crossword {
        const filledPositions: Position[] = [];
        json.forEach((row, rowIndex) => {
            row.forEach((cell, columnIndex) => {
                if (cell === null) {
                    filledPositions.push(new Position(rowIndex, columnIndex));
                }
            })
        });
        const layout = new Layout(json.length, filledPositions);
        const puzzle = new Crossword(layout);
        layout.forEach((position) => {
            const value = json[position.row][position.column];
            if (value === null) {
                puzzle.set(position, FILLED);
            } else if (value === " ") {
                puzzle.set(position, EMPTY);
            } else {
                puzzle.set(position, value);
            }
        });
        return puzzle;
    }

    static isFilled(value: Filled | Empty | string) {
        return value === FILLED;
    }

    isCompleted(): boolean {
        let completed = true;
        this.forEach((_pos, value) => {
            if (value === EMPTY) {
                completed = false;
            }
        });
        return completed;
    } 

    get id() {
        return md5(this.toString());
    }

    getClue(clueId: string) {
        const clue = this.layout.getClues().find((clue) => clue.id === clueId);
        if (clue === undefined) {
            throw new Error("Expected clue to be present.");
        }
        return clue;
    }

    emptyPositions(): Position[] {
        const result: Position[] = [];
        this.forEach((position, value) => {
            if (value === EMPTY) {
                result.push(position);
            }
        });
        return result;
    }
}

export enum GameState {
    InProgress = "in_progress",
    Win = "win",
    Lose = "lose",
}

class TrieNode {
    private children: Map<string, TrieNode> = new Map();
    private _isTerminal: boolean = false;
    private _isEnabled: boolean = true;

    public get isTerminal() {
        return this._isTerminal;
    }

    private set isTerminal(isTerminal: boolean) {
        this._isTerminal = isTerminal;
    }

    public get isEnabled() {
        return this._isEnabled;
    }

    private set isEnabled(isEnabled: boolean) {
        this._isEnabled = isEnabled;
    }

    constructor(public readonly key: string) {}

    public _get(word: string): TrieNode | undefined {
        if (word.length === 0) {
            return this;
        }
        const head = word[0];
        const rest = word.slice(1);
        const next = this.children.get(head);
        if (next) {
            return next._get(rest);
        }
        return undefined;
    }

    public add(word: string) {
        if (word.length === 0) {
            this.isTerminal = true;
            return;
        }
        const head = word[0];
        const rest = word.slice(1);
        let next = this.children.get(head);
        if (!next) {
            next = new TrieNode(head);
            this.children.set(head, next);
        }
        next.add(rest);
    }

    has(word: string): boolean {
        const terminal = this._get(word);
        return terminal !== undefined && terminal.isTerminal
    }

    hasPrefix(prefix: string): boolean {
        const terminal = this._get(prefix);
        return terminal !== undefined;
    }

    disable(word: string): void {
        const terminal = this._get(word);
        if (terminal) {
            terminal.isEnabled = false;
        }
    }

    enable(word: string): void {
        const terminal = this._get(word);
        if (terminal) {
            terminal.isEnabled = true;
        }
    }

    public match(pattern: string): string[] {
        if (pattern.length === 0) {
            if (this.isTerminal && this.isEnabled) {
                return [this.key];
            } else {
                return[];
            }
        }
        const head = pattern[0];
        const rest = pattern.slice(1);
        if (head === ".") {
            return Array.from(this.children.values()).flatMap((child) => {
                return child.match(rest).map((str) => this.key + str);
            });
        } else {
            const next = this.children.get(head);
            if (next) {
                return next.match(rest).map((str) => this.key + str);
            }
            return [];
        }
    }

    public hasMatch(pattern: string): boolean {
        if (pattern.length === 0) {
            if (this.isTerminal && this.isEnabled) {
                return true;
            }
        }
        const head = pattern[0];
        const rest = pattern.slice(1);
        if (head === ".") {
            for (const child of Array.from(this.children.values())) {
                if (child.hasMatch(rest)) {
                    return true;
                }
            }
        } else {
            const next = this.children.get(head);
            if (next) {
                return next.hasMatch(rest);
            }
        }
        return false;
    }
}

export class Trie extends TrieNode {
    constructor() {
        super("");
    }
}

export class Lexicon extends Trie {
}

interface WordleResultWrongLength {
    status: "wrong-length"
}

interface WordleResultInvalidWord {
    status: "invalid-word"
    scores: WordleScore[]
}

interface WordleResultWin {
    status: "win"
    scores: WordleScore[]
}

interface WordleResultLose {
    status: "lose"
    scores: WordleScore[]
}

type WordleResult = 
    | WordleResultWrongLength 
    | WordleResultInvalidWord 
    | WordleResultWin 
    | WordleResultLose;

function makeInitWordlesState(
    puzzle: Crossword, 
    maxGuesses: number
): Map<string, Wordle> {
    const wordles = new Map();
    
    puzzle.layout.getClues().forEach((clue) => {
        wordles.set(clue.id, new Wordle(
            clue, 
            puzzle.patternFor(clue), 
            [], 
            [],
            "", 
            maxGuesses
        ));
    });

    return wordles;
}

interface CrosswordleJSON {
    id: string;
    wordles: WordleJSON[];
    grid: (string | null)[][];
    currentClueId: string;
    playTime: number;
}

interface WordleJSON {
    clueId: string,
    guesses: string[],
    guessOrder: number[] | undefined,
    guess: string,
}

export class Wordle {
    public guessInvalid: boolean = false;

    constructor(
        public readonly clue: Clue,
        public readonly answer: string,
        public readonly guesses: string[],
        public readonly guessOrder: number[],
        public guess: string,
        public readonly maxGuesses: number,
    ) {}

    static score(word: string, guess: string, lexicon: Lexicon): WordleResult {
        if (guess.length !== word.length) {
            return { status: "wrong-length" };
        } else if (word === guess) {
            return { 
                status: "win", 
                scores: buildArray(word.length, () => WordleScore.Hit) 
            };
        } else if (!lexicon.has(guess)) {
            return { 
                status: "invalid-word",
                scores: buildArray(word.length, () => WordleScore.Invalid) 
             };
        } else {
            const scores = buildArray(word.length, () => WordleScore.Miss);
            let remainingLetters = word.split("");
            for (let index = 0; index < word.length; index++) {
                const guessLetter = guess.charAt(index);
                const wordLetter = word.charAt(index);
                const foundIndex = remainingLetters.indexOf(guessLetter);
                if (guessLetter === wordLetter) {
                    scores[index] = WordleScore.Hit;
                    remainingLetters.splice(foundIndex, 1);
                }
            }
            for (let index = 0; index < word.length; index++) {
                const guessLetter = guess.charAt(index);
                const wordLetter = word.charAt(index);
                const foundIndex = remainingLetters.indexOf(guessLetter);
                if (guessLetter !== wordLetter && foundIndex !== -1) {
                    scores[index] = WordleScore.NearMiss;
                    remainingLetters.splice(foundIndex, 1);
                }
            }
            return { status: "lose", scores };
        }
    }

    toJSON(): WordleJSON {
        return {
            clueId: this.clue.id,
            guesses: this.guesses,
            guessOrder: this.guessOrder,
            guess: this.guess,
        }
    }

    static fromJSON(crossword: Crossword, maxGuesses: number, json: WordleJSON) {
        const clue = crossword.getClue(json.clueId);
        return new Wordle(
            clue, 
            crossword.patternFor(clue), 
            json.guesses, 
            json.guessOrder || [],
            json.guess, 
            maxGuesses
        );
    }

    isLost() {
        return this.guesses.length === this.maxGuesses;
    }

    get length() {
        return this.answer.length;
    }

    enterLetter(letter: string) {
        const newGuess = this.guess + letter;
        if (newGuess.length > this.length) {
            return;
        }
        this.guess = newGuess;
    }

    deleteLetter() {
        this.guess = this.guess.slice(0, this.guess.length - 1);
        this.guessInvalid = false;
    }

    enterGuess(lexicon: Lexicon, guessNumber: number): WordleResultWin | WordleResultLose | undefined {
        const result = Wordle.score(
            this.answer, 
            this.guess, 
            lexicon
        );
        if (result.status === "wrong-length") {
            return undefined;
        }
        if (result.status === "invalid-word") {
            this.guessInvalid = true;
            return undefined;
        }
        this.guessInvalid = false;
        this.guesses.push(this.guess);
        this.guessOrder.push(guessNumber);
        this.guess = "";
        return result;
    }

    missedLetters(position: Position) {
        const index = this.clue.positions.findIndex((other) => other.isEqual(position));
        const correct = this.answer[index];
        return this.guesses
            .map((guess) => guess[index])
            .filter((letter) => letter !== correct);
    }
}

export class Crosswordle {
    currentClue: Clue;
    wordles: Map<string, Wordle>;
    grid: Crossword;
    playTime: number = 0;

    constructor(
        public readonly crossword: Crossword, 
        public readonly maxGuesses: number,
    ) {
        this.currentClue = crossword.layout.getClues()[0];
        this.wordles = makeInitWordlesState(crossword, maxGuesses);
        this.grid = new Crossword(crossword.layout);
    }

    toJSON(): CrosswordleJSON {
        return {
            id: this.crossword.id,
            wordles: [...this.wordles.values()].map((wordle) => wordle.toJSON()),
            grid: this.grid.toJSON(),
            currentClueId: this.currentClue.id,
            playTime: this.playTime
        }
    }

    isLost(): boolean {
        let lost = false;
        this.grid.forEach((position, value) => {
            if (value === EMPTY) {
                const [clue1, clue2] = this.crossword.layout.cluesAt(position);
                const wordle1 = this.getWordle(clue1);
                const wordle2 = this.getWordle(clue2);
                if (wordle1.isLost() && wordle2.isLost()) {
                    lost = true;
                }
            }
        });
        return lost;
    }

    static fromJSON(
        puzzle: Crossword, 
        maxGuesses: number, 
        json: CrosswordleJSON
    ): Crosswordle {
        const newCrosswordle = new Crosswordle(puzzle, maxGuesses);
        newCrosswordle.wordles = new Map();
        json.wordles.forEach((wordleJSON) => {
            newCrosswordle.wordles.set(
                wordleJSON.clueId,
                Wordle.fromJSON(puzzle, maxGuesses, wordleJSON)
            );
        });
        newCrosswordle.grid = Crossword.fromJSON(json.grid);
        newCrosswordle.currentClue = puzzle.layout.getClues().find(
            (clue) => clue.id === json.currentClueId
        )!;
        newCrosswordle.playTime = json.playTime || 0;
        return newCrosswordle;
    }

    get guessCount() {
        return [...this.wordles.values()]
            .map((wordle) => wordle.guesses.length)
            .reduce((a, b) => a + b, 0);
    }

    get maxGuessCount() {
        return this.wordles.size * this.maxGuesses;
    }

    clone(): Crosswordle {
        const newCrosswordle = new Crosswordle(this.crossword.clone(), this.maxGuesses);
        newCrosswordle.wordles = new Map();
        this.wordles.forEach((wordle) => {
            newCrosswordle.wordles.set(wordle.clue.id, wordle);
        });
        newCrosswordle.grid = this.grid.clone();
        newCrosswordle.currentClue = this.currentClue;
        newCrosswordle.playTime = this.playTime;
        return newCrosswordle;
    }

    get gameState() {
        if (this.grid.isCompleted()) {
            return GameState.Win;
        } else if (this.isLost()) {
            return GameState.Lose;
        }
        return GameState.InProgress;
    }

    get currentWordle(): Wordle {
        return this.getWordle(this.currentClue);
    }

    getWordle(clue: Clue) {
        const wordle = this.wordles.get(clue.id);
        if (wordle === undefined) {
            throw new Error("Expected wordle to be present");
        }
        return wordle;
    }

    enterLetter(letter: string) {
        this.currentWordle.enterLetter(letter);
    }

    deleteLetter() {
        this.currentWordle.deleteLetter();
    }

    enterGuess(lexicon: Lexicon) {
        const current = this.currentWordle;
        const result = current.enterGuess(lexicon, this.guessCount);
        if (result) {
            // TODO grid could be computed
            const newGrid = this.grid.clone();
            for (let index = 0; index < current.length; index++) {
                const score = result.scores[index];
                if (score === WordleScore.Hit) {
                    newGrid.set(
                        this.currentClue.positions[index], 
                        current.answer[index]
                    );
                }
            }
            this.grid = newGrid;
        }
        return result;
    }

    get currentPosition(): Position {
        const index = Math.min(
            this.currentWordle.guess.length,
            this.currentWordle.length - 1,
        )
        return this.currentClue.positions[index];
    }

    get crossClue(): Clue {
        return this.crossword.layout.otherClue(this.currentClue, this.currentPosition);
    }

    get currentGuess(): string {
        return this.currentWordle.guess;
    }

    keyColors(lexicon: Lexicon): Map<string, KeyColor> {
        const colors = new Map();
        for (const guess of this.currentWordle.guesses) {
            const result = Wordle.score(this.currentWordle.answer, guess, lexicon);
            if (result.status === "wrong-length") {
                continue;
            }
            for (let index = 0; index < this.currentWordle.length; index++) {
                const score = result.scores[index];
                if (score === WordleScore.Hit) {
                    colors.set(guess[index], {
                        base: KeyColorBase.Hit,
                        crossInvalid: false,
                    });
                } else if (score === WordleScore.Miss) {
                    if (!colors.has(guess[index])) {
                        colors.set(guess[index], {
                            base: KeyColorBase.Miss,
                            crossInvalid: false,
                        });
                    }
                } else if (score === WordleScore.NearMiss) {
                    if (colors.get(guess[index]) !== WordleScore.Hit) {
                        colors.set(guess[index], {
                            base: KeyColorBase.NearMiss,
                            crossInvalid: false,
                        });
                    }
                }
            }
        }
        const crossWordle = this.getWordle(this.crossClue);
        const crossInvalidLetters1 = crossWordle.missedLetters(this.currentPosition);
        const crossInvalidLetters2 = crossWordle.guesses
            .flatMap((guess) => guess.split(""))
            .filter((letter) => !crossWordle.answer.includes(letter));
        const crossInvalidLetters = [...crossInvalidLetters1, ...crossInvalidLetters2];
        for (const crossInvalidLetter of crossInvalidLetters) {
            const existing = colors.get(crossInvalidLetter);
            if (existing !== undefined) {
                existing.crossInvalid = true;
            } else {
                colors.set(crossInvalidLetter, {
                    base: KeyColorBase.Blank,
                    crossInvalid: true,
                });
            }
        }
        for (const position of this.currentClue.positions) {
            const letter = this.grid.get(position);
            const existing = colors.get(letter);
            if (letter !== EMPTY && letter !== FILLED) {
                colors.set(letter, {
                    base: KeyColorBase.Hit,
                    crossInvalid: existing && existing.crossInvalid,
                });
            }
        }
        return colors;
    }

    setPlayTime(playTime: number | ((playTime: number) => number)) {
        if (playTime instanceof Function) {
            this.playTime = playTime(this.playTime);
        } else {
            this.playTime = playTime;
        }
    }
}

export interface CrosswordleScore {
    didWin: boolean;
    guessCount: number;
    crosswordleNumber: number;
}

interface RawStatistics {
    playCount: number;
    winPercentage: number | undefined;
    currentStreak: number;
    longestStreak: number;
    scoreDistribution: Map<number, number>;
}

export class Statistics {
    public static readonly BUCKET_SIZE = 5;
    private _statistics?: RawStatistics;

    constructor(public readonly scores: CrosswordleScore[]) {}

    get statistics(): RawStatistics {
        if (this._statistics === undefined) {
            const playCount = this.scores.length;
            let winCount = 0;
            let currentStreak = 0;
            let longestStreak = 0;
            let scoreDistribution = new Map();
            let lastPuzzleNumber: undefined | number = undefined;

            for (const score of this.scores) {
                if (score.didWin) {
                    winCount++;
                    if (
                        lastPuzzleNumber === undefined 
                        || lastPuzzleNumber === score.crosswordleNumber - 1
                    ) {
                        currentStreak += 1;
                        if (currentStreak > longestStreak) {
                            longestStreak = currentStreak;
                        }
                    } else {
                        currentStreak = 1;
                    }
                    const bucket = Math.max(6, Math.floor(
                        (score.guessCount - 1) / Statistics.BUCKET_SIZE
                    ) * Statistics.BUCKET_SIZE + 1);
                    scoreDistribution.set(
                        bucket,
                        (scoreDistribution.get(bucket) ?? 0) + 1
                    );
                } else {
                    currentStreak = 0;
                }
                lastPuzzleNumber = score.crosswordleNumber;
            }

            const winPercentage = playCount > 0 ? winCount / playCount : undefined;
            this._statistics = {
                playCount,
                winPercentage,
                currentStreak,
                longestStreak,
                scoreDistribution,
            };
        }
        return this._statistics;
    }

    get currentStreak() {
        return this.statistics.currentStreak;
    }
    
    get longestStreak() {
        return this.statistics.longestStreak;
    }

    get playCount() {
        return this.statistics.playCount;
    }

    get winPercentage() {
        return this.statistics.winPercentage;
    }

    get scoreDistribution() {
        return this.statistics.scoreDistribution;
    }

    get lastScore() {
        return this.scores[this.scores.length - 1];
    }
}