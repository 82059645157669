import { range } from "../../utils";
import { Crossword } from "../base";

export const ICON_PUZZLE = Crossword.fromJSON([
    ["a", "w", "w", "a", "m"], 
    ["c", "r", "o", "s", "s"], 
    ["a", "n", "r", "e", "l"], 
    ["s", "k", "d", "l", "e"], 
    ["h", "o", "t", "l", "y"]
])

const CURRENT_BATCHES = range(13, 34);

function batchesForDate(date: Date) {
    if (date <= new Date(2022, 1, 23)) {
        return range(0, 0);
    } else if (date <= new Date(2022, 2, 3)) {
        return range(1, 12);
    } else {
        return CURRENT_BATCHES;
    }
}

function adjustmentForDate(date: Date) {
    if (date.getTime() === Date.UTC(2022, 2, 6)) {
        return 1;
    } else {
        return 0;
    }
}

function seededRandom(a: number): number {
    var t = a += 0x6D2B79F5;
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
}

export function getDailyPuzzleSeed(time: Date): number {
    const utcTime = Date.UTC(time.getFullYear(), time.getMonth(), time.getDate());
    const date = new Date(utcTime);
    const seed = date.getTime() + adjustmentForDate(date);
    return seed;
}

export async function getDailyPuzzle(time: Date): Promise<Crossword> {
    const utcTime = Date.UTC(time.getFullYear(), time.getMonth(), time.getDate());
    const date = new Date(utcTime);
    const seed = getDailyPuzzleSeed(time);
    const batches = batchesForDate(date);
    return getPuzzle(seed, batches);
}

export async function getPuzzle(
    seed: number, 
    batches = CURRENT_BATCHES
): Promise<Crossword> {
    const random = seededRandom(seed);
    const batchNumber = batches[Math.floor(random * batches.length)];
    const batch = (await import(`./puzzles_${batchNumber}`)).default;
    const index = Math.floor(random * batch.length);
    return Crossword.fromStorageString(batch[index]);
}