import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { getDailyPuzzle, getPuzzle } from "../core/puzzles";
import { getCrosswordleNumber } from "../utils";
import { CrosswordleUI } from "./CrosswordleUI";
import { useLocalGameState, useLocalState, useStatistics } from "./hooks";
import { Info } from "./Info";
import { Settings } from "./Settings";
import { getTheme, Theme } from "./themes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faBullseye,
    faCalendarDay, 
    faChartColumn, 
    faGear, 
    faInfoCircle, 
    faTimes 
} from '@fortawesome/free-solid-svg-icons'
import CookieBanner from "react-cookie-banner"
import { StatisticsUI } from "./StatisticsUI";
import { getDailyPuzzleSeed } from "../core/puzzles/fetch";

const MAX_GUESSES = 4;

type PageId = "main" | "info" | "settings" | "statistics";

export const App: React.FC = () => {
    const { statistics, addScore } = useStatistics();
    const [page, setPage] = useState<PageId>("main");
    const isPlaying = page === "main";
    const { 
        crosswordle, 
        crosswordleNumber, 
        setCrosswordle, 
        setCrossword
    } = useLocalGameState(isPlaying);

    const [highContrast, setHighContrast] = useLocalState("high-contrast", false);
    const [showCrossInfo, setShowCrossInfo] = useLocalState("show-cross-info", false);

    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const [darkMode, setDarkMode] = useLocalState("dark-mode", prefersDarkScheme);

    const [
        swapSpecialKeys, 
        setSwapSpecialKeys
    ] = useLocalState("swap-special-keys", true);

    const [practiceCountToday, setPracticeCountToday] = useLocalState<number | null>(
        "practice-puzzles-completed-today", null
    );
    const [practiceSeed, setPracticeSeed] = useLocalState<number | null>(
        "practice-seed", null
    );

    const theme = getTheme(highContrast, darkMode);

    const dailyPuzzle = () => {
        setPage("main");
        const crosswordleNumber = getCrosswordleNumber();
        getDailyPuzzle(new Date()).then((crossword) => {
            setCrossword(crossword, crosswordleNumber, MAX_GUESSES);
        });
    }

    useEffect(dailyPuzzle, [setCrossword]);

    const practicePuzzle = async (newPracticeCountToday: number | null = null) => {
        setPage("main");
        let seed: number;
        let offset = newPracticeCountToday ?? practiceCountToday ?? 0;
        const firstPracticeSeedToday = getDailyPuzzleSeed(new Date()) + 1;
        if (practiceSeed !== firstPracticeSeedToday) {
            seed = firstPracticeSeedToday;
            offset = 0;
            setPracticeSeed(seed);
        } else {
            seed = practiceSeed + offset;
        }
        const crossword = await getPuzzle(seed);
        setPracticeCountToday(offset)
        setCrossword(crossword, undefined, MAX_GUESSES);
    }

    const nextPracticePuzzle = async () => {
        practicePuzzle((practiceCountToday ?? 0) + 1);
    }

    return <MainUI theme={theme}>
        <style>
            {`
            body {
                background-color: ${theme.background};
            }
            body, #root {
                height: 100%;
            }
            html {
                height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
            }
            `}
        </style>
        <Header theme={theme}>
            <LeftButtons>
                {
                    page === "info" 
                    ? <IconButton 
                        key="go-main"
                        icon={faTimes} 
                        color={theme.hit} 
                        onClick={() => setPage("main")} 
                        size="lg"
                    />
                    : <IconButton 
                        key="go-info"
                        icon={faInfoCircle} 
                        color={theme.hit} 
                        onClick={() => setPage("info")} 
                        size="lg"
                    />
                }
                {
                    page === "statistics" 
                    ? <IconButton 
                        key="go-main"
                        icon={faTimes} 
                        color={theme.hit} 
                        onClick={() => setPage("main")} 
                        size="lg"
                    />
                    : <IconButton 
                        key="go-statistics"     
                        icon={faChartColumn} 
                        color={theme.hit} 
                        onClick={() => setPage("statistics")} 
                        size="lg"
                    />
                }
            </LeftButtons>
            <Title>Crosswordle</Title>
            <RightButtons>
                {
                    crosswordleNumber 
                    ? <IconButton  
                        key="go-practice"
                        icon={faBullseye} 
                        color={crosswordleNumber ? theme.miss : theme.hit} 
                        onClick={() => practicePuzzle()} 
                        size="lg"
                    /> 
                    : <IconButton 
                        key="go-daily"
                        icon={faCalendarDay} 
                        color={crosswordleNumber ? theme.hit : theme.miss} 
                        onClick={dailyPuzzle} 
                        size="lg"
                    />
                }
                {
                    page === "settings" 
                    ? <IconButton 
                        key="go-main"
                        icon={faTimes} 
                        color={theme.hit} 
                        onClick={() => setPage("main")} 
                        size="lg"
                    />
                    : <IconButton 
                        key="go-settings"
                        icon={faGear} 
                        color={theme.hit} 
                        size="lg"
                        onClick={() => setPage("settings")} 
                    />
                }
            </RightButtons>
        </Header>
        { crosswordle && <MaybeShow show={page === "main"}>
            <CrosswordleUI 
                crosswordle={crosswordle}
                crosswordleNumber={crosswordleNumber} 
                practiceNumber={practiceCountToday}
                theme={theme}
                showCrossInfo={showCrossInfo}
                startNewPracticeGame={nextPracticePuzzle}
                setCrosswordle={setCrosswordle}
                addScore={addScore}
                swapSpecialKeys={swapSpecialKeys}
            />
        </MaybeShow> }
        { page === "main" && !crosswordle && <Loading>Loading puzzle...</Loading> }
        { page === "info" && <>
            <Info theme={theme} />
        </>}
        { page === "settings" && <>
            <Settings 
                highContrast={highContrast} 
                setHighContrast={setHighContrast}
                showCrossInfo={showCrossInfo}
                setShowCrossInfo={setShowCrossInfo}
                darkMode={darkMode}
                setDarkMode={setDarkMode}
                swapSpecialKeys={swapSpecialKeys}
                setSwapSpecialKeys={setSwapSpecialKeys}
                theme={theme}
            />
        </>}
        { page === "statistics" && <>
            <StatisticsUI statistics={statistics} theme={theme} />
        </>}
        <CookieBannerContainer theme={theme}><CookieBanner
            message='Crosswordle uses cookies to track user engagement.'
            dismissOnScroll={false}
            disableStyle={true}
        /></CookieBannerContainer>
    </MainUI>;
}

const Header = styled.div<{
    theme: Theme;
}>`
    position: fixed;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    ${({ theme }) => `
        background-color: ${theme.background};
        color: ${theme.foreground};
        border-bottom: 1px solid ${theme.borderLight};
    `}
`;

const Title = styled.h2`
    margin-top: 20px;
`;

const MainUI = styled.div<{
    theme: Theme;
}>`
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: Arial;
    user-select: none;
    height: 100%;
    ${({ theme }) => `background-color: ${theme.background}`}
`;

const Loading = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

const Buttons = styled.div`
    width: 25%;
    display: flex;
    gap: 15px;
    margin: 0px 20px;
`;

const LeftButtons = styled(Buttons)`
    justify-content: flex-start;
`;

const RightButtons = styled(Buttons)`
    justify-content: flex-end;
`;

const IconButton = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const CookieBannerContainer = styled.div<{ 
    theme: Theme 
}>`
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
    ${({ theme }) => `
        .button-close {
            border-radius: 0;
            background-color: ${theme.hit};
            outline: none;
            padding: 5px 10px;
            border: none; 
            color: white;    
            width: 70px; 
            font-size: 14px;
        }
        .react-cookie-banner {
            background-color: ${theme.background};
            color: ${theme.foreground};
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid ${theme.borderLight};
        }
    `}
`;

const MaybeShow = styled.div<{
    show: boolean;
}>`
    height: 100%;
    ${({ show }) => `display: ${show ? 'unset' : 'none'}`}
`;