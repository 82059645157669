import { useCallback, useEffect, useRef, useState } from "react";
import { Crosswordle, Crossword, Statistics, CrosswordleScore, GameState } from "../../core";

function getLocalValue<T>(key: string, defaultValue: T) {
    try {
        const value = JSON.parse(localStorage.getItem(key) || "null");
        if (value === null) {
            return defaultValue;
        }
        return value;
    } catch (error) {
        return defaultValue;
    }
}

function setLocalValue<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function useLocalState<T>(key: string, defaultValue: T): [
    T, 
    React.Dispatch<React.SetStateAction<T>>
] {
    const [memVal, setMemVal] = useState<T>(getLocalValue(key, defaultValue));

    useEffect(() => setLocalValue(key, memVal), [key, memVal]);

    return [memVal, setMemVal];
}

function keyFor(puzzleNumber: number | undefined) {
    const isDaily = puzzleNumber !== undefined;
    return isDaily ? "daily-progress" : "practice-progress";
}

export function useLocalGameState(isPlaying: boolean): {
    crosswordle: Crosswordle | undefined,
    crosswordleNumber: number | undefined,
    setCrosswordle: (crosswordle: Crosswordle) => void,
    setCrossword: (
        crossword: Crossword, 
        puzzleNumber: number | undefined, 
        maxGuesses: number
    ) => void;
} {
    const [crosswordle, setCrosswordle] = useState<Crosswordle>();
    const [puzzleNumber, setPuzzleNumber] = useState<number | undefined>();
    const [, setCurrentPlayTime] = useState(0);

    const isTimerRunning = 
        isPlaying 
        && crosswordle !== undefined 
        && crosswordle.gameState === GameState.InProgress
        && crosswordle.guessCount > 0;

    useStopwatch(isTimerRunning, (action) => {
        crosswordle?.setPlayTime(action);
        // This is sort of annoying. We just update some state every second
        // so that we rerender.
        setCurrentPlayTime(action);
    });

    const setCrossword = useCallback((
        crossword: Crossword, 
        puzzleNumber: number | undefined, 
        maxGuesses: number
    ) => {
        setCrosswordle(loadCrosswordle(crossword, puzzleNumber, maxGuesses));
        setPuzzleNumber(puzzleNumber);
    }, []);

    const setCrosswordleOuter = (crosswordle: Crosswordle) => {
        setLocalValue(keyFor(puzzleNumber), crosswordle);
        setCrosswordle(crosswordle.clone());
    }

    return {
        crosswordle,
        setCrosswordle: setCrosswordleOuter,
        setCrossword,
        crosswordleNumber: puzzleNumber
    };
}

export function loadCrosswordle(
    crossword: Crossword, 
    puzzleNumber: number | undefined, 
    maxGuesses: number
) {
    const id = crossword.id;
    const blankCrosswordle = new Crosswordle(crossword, maxGuesses);
    let initial: Crosswordle;
    const savedPuzzle = getLocalValue(keyFor(puzzleNumber), undefined);
    if (savedPuzzle && savedPuzzle.id === id) {
        try {
            initial = Crosswordle.fromJSON(crossword, maxGuesses, savedPuzzle);
        } catch (error) {
            console.log("Error loading stored progress: ", error);
            initial = blankCrosswordle;
        }
    } else {
        initial = blankCrosswordle;
    }
    return initial;
}

export function useStatistics() {
    const [scores, setScores] = useLocalState<CrosswordleScore[]>("scores", []);

    const addScore = (score: CrosswordleScore) => {
        setScores([...scores, score]);
    }

    const statistics = new Statistics(scores);

    return { statistics, addScore };
}

export function useStopwatch(
    isRunning: boolean, 
    setMillis: React.Dispatch<React.SetStateAction<number>>
) {
    const interval = useRef<number>();
    const INTERVAL_MILLIS = 1000;

    const clear = () => {
        const currentInterval = interval.current;
        if (currentInterval) {
            window.clearInterval(currentInterval);
        }
    }

    useEffect(() => {
        if (isRunning) {
            interval.current = window.setInterval(() => {
                setMillis((millis) => millis + INTERVAL_MILLIS);
            }, INTERVAL_MILLIS);
        } else {
            clear();
        }
        return () => clear();
    }, [isRunning, setMillis]);
}