import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTurnUp, faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components"
import { trackSetAdvancedKeyboard, trackSetDarkMode, trackSetHighContrast, trackSetSwapSpecialKeys } from "../../analytics";
import { Theme } from "../themes";
import { Toggle } from "../Toggle";

interface SettingsProps {
    highContrast: boolean;
    setHighContrast: (highContrast: boolean) => void;
    showCrossInfo: boolean;
    setShowCrossInfo: (showCrossInfo: boolean) => void;
    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
    swapSpecialKeys: boolean;
    setSwapSpecialKeys: (swapSpecialKeys: boolean) => void;
    theme: Theme;
}

export const Settings: React.FC<SettingsProps> = ({
    highContrast,
    setHighContrast,
    showCrossInfo,
    setShowCrossInfo,
    darkMode,
    setDarkMode,
    swapSpecialKeys,
    setSwapSpecialKeys,
    theme,
}) => {
    const setAndTrackHighContrast = (highContrast: boolean) => {
        setHighContrast(highContrast);
        trackSetHighContrast({
            isHighContrastEnabled: highContrast,
        });
    }

    const setAndTrackShowCrossInfo = (showCrossInfo: boolean) => {
        setShowCrossInfo(showCrossInfo);
        trackSetAdvancedKeyboard({
            isAdvancedKeyboardEnabled: showCrossInfo,
        });
    }

    const setAndTrackDarkMode = (darkMode: boolean) => {
        setDarkMode(darkMode);
        trackSetDarkMode({
            isDarkModeEnabled: darkMode,
        });
    }

    const setAndTrackSwapSpecialKeys = (swapSpecialKeys: boolean) => {
        setSwapSpecialKeys(swapSpecialKeys);
        trackSetSwapSpecialKeys({
            isSwapSpecialKeysEnabled: swapSpecialKeys,
        });
    }

    const backspace = <PaddedIcon icon={faDeleteLeft} />;
    const enter = <PaddedIcon icon={faTurnUp} />;

    return <SettingsDiv theme={theme}>
        <SettingsRow>
            <SettingLabel>High contrast mode</SettingLabel>
            <Toggle 
                value={highContrast} 
                setValue={setAndTrackHighContrast} 
                theme={theme} 
            />
        </SettingsRow>
        <Hr theme={theme} />
        <SettingsRow>
            <SettingLabel>Dark mode</SettingLabel>
            <Toggle 
                value={darkMode} 
                setValue={setAndTrackDarkMode} 
                theme={theme} 
            />
        </SettingsRow>
        <Hr theme={theme} />
        <SettingsRow>
            <SettingLabel>Advanced keyboard</SettingLabel>
            <Toggle 
                value={showCrossInfo} 
                setValue={setAndTrackShowCrossInfo} 
                theme={theme} 
            />
        </SettingsRow>
        <SettingsRow>
            <Hint theme={theme}>A shaded key indicates the letter cannot be the next letter of the word (based on guesses for the crossing clue).</Hint>
        </SettingsRow>
        <Hr theme={theme} />
        <SettingsRow>
            <SettingLabel>
                Swap {swapSpecialKeys ? backspace : enter} and {swapSpecialKeys ? enter : backspace}
            </SettingLabel>
            <Toggle 
                value={swapSpecialKeys} 
                setValue={setAndTrackSwapSpecialKeys} 
                theme={theme} 
            />
        </SettingsRow>
    </SettingsDiv>
}

const SettingsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
`;

const SettingsDiv = styled.div<{
    theme: Theme;
}>`
    margin-top: 50px;
    max-width: 400px;
    padding: 20px;
    width: 100%;
    ${({ theme }) => `color: ${theme.foreground};`}
`;

const Hr = styled.hr<{
    theme: Theme;
}>`
    border: none;
    ${({ theme }) => `border-bottom: 1px solid ${theme.borderLight};`}
`;

const Hint = styled.span<{
    theme: Theme;
}>`
    ${({ theme }) => `color: ${theme.foregroundMuted};`}
`;

const PaddedIcon = styled(FontAwesomeIcon)`
    margin: 0 5px;
`;

const SettingLabel = styled.b`
    white-space: nowrap;
`;