import styled from "styled-components";
import { Theme } from "../themes";

interface ToggleProps {
    value: boolean;
    setValue: (value: boolean) => void;
    theme: Theme;
}

export const Toggle: React.FC<ToggleProps> = ({ value, setValue, theme }) => {
    return <div 
        onClick={() => setValue(!value)}
    >
        <ToggleOuter theme={theme}>
            <ToggleInner theme={theme} enabled={value} />
        </ToggleOuter>
    </div>
}

const ToggleOuter = styled.div<{
    theme: Theme;
}>`
    width: 50px;
    height: 26px;
    position: relative;
    user-select: none;
    ${({ theme }) => `background-color: ${theme.emptyHighlight}`}
`;

const ToggleInner = styled.div<{ 
    enabled: boolean,
    theme: Theme
}>`
    width: 16px;
    height: 18px;
    position: absolute;
    top: 4px;
    ${({ enabled, theme }) => 
        (enabled ? "right: 6px;" : "left: 6px;")
        + `background-color: ${enabled ? theme.hit : theme.miss }`
    }
`;