export function buildArray<T>(size: number, makeItem: (index: number) => T): T[] {
    const result = [];
    for (let index = 0; index < size; index++) {
        result.push(makeItem(index));
    }
    return result;
}

export function range(min: number, max: number): number[] {
    return buildArray(max - min + 1, (index) => index + min);
}

const WORDLE_ONE_DATE = new Date(Date.UTC(2022, 1, 22));

export function today(): Date {
    const now = new Date();
    return new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
}

export function tomorrow(): Date {
    const now = new Date();
    const nowMs = now.getTime() + ONE_DAY_IN_MILISECONDS;
    const then = new Date(nowMs);
    return new Date(Date.UTC(then.getFullYear(), then.getMonth(), then.getDate()));
}

export function todayLocal(): Date {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

export function tomorrowLocal(): Date {
    const now = new Date();
    const nowMs = now.getTime() + ONE_DAY_IN_MILISECONDS;
    const then = new Date(nowMs);
    return new Date(then.getFullYear(), then.getMonth(), then.getDate());
}

const ONE_DAY_IN_MILISECONDS = 1000 * 60 * 60 * 24;

export function getCrosswordleNumber() {
    const now = today();
    return 1 + Math.round(
        (now.getTime() - WORDLE_ONE_DATE.getTime()) / ONE_DAY_IN_MILISECONDS
    );
}

export function stripes(color1: string, color2: string): string {
    return `
        background-image: linear-gradient(135deg, ${color1} 25%, ${color2} 25%, ${color2} 50%, ${color1} 50%, ${color1} 75%, ${color2} 75%, ${color2} 100%);
        background-size: 22.63px 22.63px;
    `;
}

export function formatStopwatch(time: number) {
    const totalSeconds = time / 1000;
    const seconds = Math.floor(totalSeconds % 60);
    const totalMinutes = totalSeconds / 60;
    const minutes = Math.floor(totalMinutes % 60);
    const hours = Math.floor(totalMinutes / 60);
    const showSeconds = seconds.toString().padStart(2, '0');
    const showMinutes = hours >= 1 ? minutes.toString().padStart(2, '0') : minutes.toString();
    const showHours = hours.toString().padStart(2, '0');
    return (hours >= 1 ? `${showHours}:` : "") + `${showMinutes}:${showSeconds}`
}