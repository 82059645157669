import { faTurnUp, faDeleteLeft, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { KeyColor } from "../../core";
import { KeyUI } from "../KeyUI";
import { Theme } from "../themes";

interface Key {
    symbol: IconDefinition | string; 
    key: string;
}

interface KeyboardProps {
    keyPress: (key: string) => void;
    letterColors?: Map<string, KeyColor>;
    theme: Theme;
    showCrossInfo: boolean;
    swapSpecialKeys: boolean;
}

function getKeyboardLayout(swapSpecialKeys: boolean): (string | Key)[][] {
    const backspace = { symbol: faDeleteLeft, key: 'Backspace' };
    const enter = { symbol: faTurnUp, key: 'Enter' };
    const [lSpecial, rSpecial] = swapSpecialKeys 
        ? [backspace, enter] 
        : [enter, backspace];
    return [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
        [lSpecial, 'z', 'x', 'c', 'v', 'b', 'n', 'm', rSpecial]
    ];
}

function splitKey(_key: string | Key): Key {
    const symbol = typeof _key === "string" ? _key : _key.symbol;
    const key = typeof _key === "string" ? _key : _key.key;
    return { symbol, key };
}

export const Keyboard: React.FC<KeyboardProps> = ({ 
    keyPress, 
    letterColors, 
    theme,
    showCrossInfo,
    swapSpecialKeys,
}) => {
    return <KeyboardUI>
        { getKeyboardLayout(swapSpecialKeys).map((row, index) => {
            return <RowUI key={index}>
                {row.map((_key) => {
                    const { key, symbol } = splitKey(_key);
                    const handler = () => keyPress(key);
                    return <KeyUI 
                        key={key}
                        theme={theme}
                        showCrossInfo={showCrossInfo}
                        colorCode={letterColors?.get(key)}
                        wide={typeof symbol !== "string"}
                        onClick={handler}
                        onTouchStart={handler}
                        onTouchEnd={(event) => event.preventDefault()}
                    >
                        {typeof symbol !== "string" 
                            ? <FontAwesomeIcon icon={symbol} /> 
                            : symbol}
                    </KeyUI>
                })}
            </RowUI>
        })}
    </KeyboardUI>
};

const KeyboardUI = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
    margin: 5px;
    max-width: calc(100vw - 10px);
    margin-bottom: 130px;
    margin-top: 30px;
`;

const RowUI = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3px;
`;