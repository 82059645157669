export interface Theme {
    hit: string;
    hitMuted: string;
    miss: string;
    missForeground: string;
    missMuted: string;
    invalid: string;
    filled: string;
    nearMiss: string;
    nearMissMuted: string;
    empty: string;
    emptyKey: string;
    emptyHighlight: string;
    emptyForeground: string;
    hitMutedForeground: string;
    nearMissMissMix: string;
    hitMissMix: string;
    background: string;
    foreground: string;
    foregroundMuted: string;
    borderDark: string;
    borderLight: string;
}

export const THEMES: { 
    default: Theme, 
    highContrast: Theme,
    defaultDark: Theme,
    highContrastDark: Theme,
} = {
    highContrast: {
        filled: "black",
        hit: "#f5793a",
        hitMuted: "#f5793a55",
        hitMutedForeground: "white",
        miss: "#787c7e",
        missForeground: "white",
        missMuted: "#787c7e55",
        invalid: "#e13ae1",
        nearMiss: "#85c0f9",
        nearMissMuted: "#85c0f955",
        empty: "white",
        emptyKey: "#efefef",
        emptyHighlight: "#efefef",
        emptyForeground: "#505050",
        nearMissMissMix: '#787c7e',
        hitMissMix: "#787c7e",
        background: "white",
        foreground: "black",
        foregroundMuted: "#505050",
        borderLight: "#efefef",
        borderDark: "black",
    },
    default: {
        filled: "black",
        hit: "#6aaa64",
        hitMuted: "#6aaa6455",
        hitMutedForeground: "white",
        miss: "#787c7e",
        missForeground: "white",
        nearMiss: "#c9b458",
        nearMissMuted: "#c9b45855",
        missMuted: "#787c7e55",
        invalid: "#c93232",
        empty: "white",
        emptyKey: "#efefef",
        emptyHighlight: "#efefef",
        emptyForeground: "#505050",
        nearMissMissMix: "#a79f7e",
        hitMissMix: "#6d896a",
        background: "white",
        foreground: "black",
        foregroundMuted: "#505050",
        borderLight: "#efefef",
        borderDark: "black",
    },
    highContrastDark: {
        filled: "black",
        hit: "#f5793a",
        hitMuted: "#f5793a77",
        hitMutedForeground: "#ffffffde",
        miss: "#505051",
        missForeground: "#ffffffde",
        missMuted: "#787c7e77",
        invalid: "#e13ae1",
        nearMiss: "#85c0f9",
        nearMissMuted: "#85c0f977",
        empty: "#313131",
        emptyKey: "#292929",
        emptyHighlight: "#292929",
        emptyForeground: "#ffffffde",
        nearMissMissMix: '#787c7e',
        hitMissMix: "#787c7e",
        background: "#121213",
        foreground: "#ffffffde",
        foregroundMuted: "#505050",
        borderLight: "#3a3a3c",
        borderDark: "#000000",
    },
    defaultDark: {
        filled: "black",
        hit: "#568951",
        hitMuted: "#6aaa6477",
        hitMutedForeground: "#ffffffde",
        miss: "#505051",
        missForeground: "#ffffffde",
        nearMiss: "#a9984b",
        nearMissMuted: "#c9b45877",
        missMuted: "#787c7e55",
        invalid: "#c93232",
        empty: "#313131",
        emptyHighlight: "#292929",
        emptyKey: "#292929",
        emptyForeground: "#ffffffde",
        nearMissMissMix: "#63604f",
        hitMissMix: "#455743",
        background: "#121213",
        foreground: "#ffffffde",
        foregroundMuted: "#505050",
        borderLight: "#3a3a3c",
        borderDark: "#000000",
    }
}

export function getTheme(highContrast: boolean, darkMode: boolean) {
    return highContrast 
        ? darkMode
        ? THEMES.highContrastDark
        : THEMES.highContrast
        : darkMode
        ? THEMES.defaultDark
        : THEMES.default;
}