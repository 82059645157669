import { Layout, Position } from "./base";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LAYOUT_0 = new Layout(5, []);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LAYOUT_1 = new Layout(5, [
    new Position(0, 0),
    new Position(4, 4),
]);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LAYOUT_2 = new Layout(5, [
    new Position(0, 3),
    new Position(0, 4),
    new Position(4, 0),
    new Position(4, 1),
]);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LAYOUT_3 = new Layout(5, [
    new Position(0, 0),
    new Position(0, 4),
    new Position(4, 0),
    new Position(4, 4),
]);

export const LAYOUTS = [
    LAYOUT_0,
    LAYOUT_1,
    LAYOUT_2,
    LAYOUT_3,
];

