import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import doNotTrack from 'analytics-plugin-do-not-track'

const ANALYTICS = Analytics({
  app: 'crosswordle',
  plugins: [
    googleAnalytics({
      trackingId: 'UA-221500763-1',
      anonymizeIp: true,
      customDimensions: {
        guess: 'dimension1',
        crosswordleNumber: 'dimension2',
        isDailyCrosswordle: 'dimension3',
        isValid: 'dimension4',
        didWin: 'dimension5',
        guessCount: 'dimension6',
        isHighContrastEnabled: 'dimension7',
        isAdvancedKeyboardEnabled: 'dimension8',
        isDarkModeEnabled: 'dimension9',
        isSwapSpecialKeysEnabled: 'dimension10',
      },
    }),
    doNotTrack(),
  ]
});

ANALYTICS.page();

export function trackGuess({ 
    guess, 
    crosswordleNumber,
    isValid,
}: { guess: string; crosswordleNumber: number | undefined, isValid: boolean }) {
    console.log('guess');
    ANALYTICS.track("crosswordle-guess", {
        guess,
        crosswordleNumber,
        isDailyCrosswordle: crosswordleNumber !== undefined,
        isValid,
    })
}

export function trackComplete({ 
    didWin, 
    crosswordleNumber,
    guessCount,
}: { 
    didWin: boolean; 
    crosswordleNumber: number | undefined, 
    guessCount: number 
}) {
    console.log('complete');
    ANALYTICS.track("crosswordle-complete", {
        didWin,
        crosswordleNumber,
        isDailyCrosswordle: crosswordleNumber !== undefined,
        guessCount,
    })
}

export function trackSetHighContrast(
    { isHighContrastEnabled }: { isHighContrastEnabled: boolean }
) {
    console.log('set-high-contrast');
    ANALYTICS.track("crosswordle-set-high-contrast", {
        isHighContrastEnabled
    });
}

export function trackSetAdvancedKeyboard(
    { isAdvancedKeyboardEnabled }: { isAdvancedKeyboardEnabled: boolean }
) {
    console.log('set-advanced-keyboard');
    ANALYTICS.track("crosswordle-set-advanced-keyboard", {
        isAdvancedKeyboardEnabled
    });
}

export function trackSetDarkMode(
    { isDarkModeEnabled }: { isDarkModeEnabled: boolean }
) {
    console.log('set-dark-mode');
    ANALYTICS.track("crosswordle-set-dark-mode", {
        isDarkModeEnabled
    });
}

export function trackSetSwapSpecialKeys(
    { isSwapSpecialKeysEnabled }: { isSwapSpecialKeysEnabled: boolean }
) {
    console.log('set-swap-special-keys');
    ANALYTICS.track("crosswordle-set-swap-special-keys", {
        isSwapSpecialKeysEnabled,
    });
}

export function trackShareSummary(params: {}) {
    console.log('share-summary');
    ANALYTICS.track("crosswordle-share-summary", {});
}

export function trackShareStatistics(params: {}) {
    console.log('share-statistics');
    ANALYTICS.track("crosswordle-share-statistics", {});
}