import { useEffect } from "react";
import { useState } from "react";

interface CountdownProps {
    end: Date;
}

function pad(num: number) {
    return num.toLocaleString("en", { minimumIntegerDigits: 2})
}

function calculateRemainingTime(date: Date) {
    const utcNow = new Date(Date.now());
    const totalSeconds = Math.floor(
        Math.max(0, (date.getTime() - utcNow.getTime()) / 1000)
    );
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    return `${hours}:${pad(minutes)}:${pad(seconds)}`;
}

export const Countdown: React.FC<CountdownProps> = ({ end }) => {
    const [remainingTime, setRemainingTime] = useState(calculateRemainingTime(end));

    useEffect(() => {
        let canceled = false;
        function loop() {
            if (canceled) { return; }
            setRemainingTime(calculateRemainingTime(end));
            setTimeout(loop, 1000);
        }
        loop();
        return () => { canceled = true; };
    });

    return <span>{remainingTime}</span>;
}