import { useEffect } from "react";
import styled from "styled-components";
import { 
    ClueDirection, 
    GameState, 
    LEXICON_PERMISSIVE, 
    Crosswordle, 
    Clue,
    CrosswordleScore
} from "../../core";
import { Keyboard } from "../Keyboard";
import { WordleUI } from "../Wordle";
import { Endgame } from "../Endgame";
import { Theme } from "../themes";
import { CrosswordUI } from "../CrosswordUI";
import { trackComplete, trackGuess } from "../../analytics";
import { formatStopwatch } from "../../utils";

interface CrosswordleUIProps {
    crosswordle: Crosswordle;
    crosswordleNumber: number | undefined;
    theme: Theme;
    showCrossInfo: boolean;
    startNewPracticeGame: () => void;
    setCrosswordle: (crosswordle: Crosswordle) => void;
    addScore: (score: CrosswordleScore) => void;
    swapSpecialKeys: boolean;
    practiceNumber: number | null;
}

export const CrosswordleUI: React.FC<CrosswordleUIProps> = ({ 
    crosswordle, 
    crosswordleNumber,
    theme,
    showCrossInfo,
    setCrosswordle,
    startNewPracticeGame,
    addScore,
    swapSpecialKeys,
    practiceNumber
}) => {
    useEffect(() => {
        const handle = (event: KeyboardEvent) => pressedKey(event.key);
        window.addEventListener(
            "keyup", 
            handle
        );
        return () => window.removeEventListener("keyup", handle);
    });

    const CHEAT_ENABLED = false;
    useEffect(() => {
        if (CHEAT_ENABLED && crosswordle.gameState !== GameState.Win) {
            for (const clue of crosswordle.crossword.layout.getClues()) {
                crosswordle.grid.fillAnswer(clue, crosswordle.crossword.patternFor(clue));
            }
            setCrosswordle(crosswordle.clone());
        }   
    }, [crosswordle, setCrosswordle, CHEAT_ENABLED]);

    const pressedKey = (key: string) => {
        const current = crosswordle.currentWordle;
        const guesses = current.guesses;
        if (guesses.length >= crosswordle.maxGuesses) {
            return;
        }
        if (
            guesses.length  
            && guesses[guesses.length - 1] === crosswordle.crossword.patternFor(crosswordle.currentClue)
        ) {
            return;
        }
        const letter = key.toLowerCase();
        if (letter.match('^[a-z]$')) {
            crosswordle.enterLetter(letter);
            setCrosswordle(crosswordle);
        } else if (key === "Enter") {
            const result = crosswordle.enterGuess(LEXICON_PERMISSIVE);
            setCrosswordle(crosswordle);
            const isValid = result?.status !== undefined;
            trackGuess({
                guess: crosswordle.currentGuess,
                crosswordleNumber,
                isValid,
            });
            if (isValid) {
                const didWin = crosswordle.grid.isCompleted();
                const didLose = crosswordle.isLost();
                if (didWin || didLose) {
                    trackComplete({
                        didWin,
                        crosswordleNumber,
                        guessCount: crosswordle.guessCount
                    });
                    if (crosswordleNumber !== undefined) {
                        addScore({
                            crosswordleNumber,
                            didWin,
                            guessCount: crosswordle.guessCount,
                        });
                    }
                }
            }
        } else if (crosswordle.currentClue && key === "Backspace") {
            crosswordle.deleteLetter();
            setCrosswordle(crosswordle);
        } 
    }

    const setCurrentClue = (currentClue: Clue) => {
        crosswordle.currentClue = currentClue;
        setCrosswordle(crosswordle);
    }

    return (
        crosswordle.gameState === GameState.InProgress 
        ? <Body>
            <TopSection>
                <CrosswordleNumber theme={theme}>
                    <div> { 
                        crosswordleNumber === undefined 
                        ? `Practice ${(practiceNumber ?? 0) + 1}` 
                        : `Day ${crosswordleNumber}`
                    } </div>
                    <div>
                        { crosswordle.guessCount } / { crosswordle.maxGuessCount }
                    </div>
                    <div>
                        { formatStopwatch(crosswordle.playTime) }
                    </div>
                </CrosswordleNumber>
                <CrosswordUI 
                    theme={theme}
                    crossword={crosswordle.grid} 
                    currentClue={crosswordle.currentClue} 
                    setCurrentClue={setCurrentClue}
                    currentGuess={crosswordle.currentGuess}
                />
                <ClueSection>
                    { crosswordle.crossword.layout
                        .getClues()
                        .map((clue) => {
                            const directionSymbol = 
                                clue.direction === ClueDirection.Across 
                                ? "→" 
                                : "↓";
                            const wordle = crosswordle.getWordle(clue);
                            const highlighted = clue.id === crosswordle.currentClue?.id;
                            const word = crosswordle.crossword.patternFor(clue);
                            return <ClueSectionItem 
                                key={clue.id} 
                                active={highlighted}
                            >
                                <ClueDiv>
                                    <NumberSection theme={theme}>
                                        <div>{clue.number}</div>
                                        <div>{directionSymbol}</div>
                                    </NumberSection>
                                    <WordleUI 
                                        onClick={() => setCurrentClue(clue)}
                                        word={word}
                                        answers={wordle.guesses}
                                        guessCount={crosswordle.maxGuesses}
                                        currentAnswer={wordle.guess}
                                        highlighed={highlighted}
                                        lexicon={LEXICON_PERMISSIVE}
                                        theme={theme}
                                        correctElsewhere={
                                            crosswordle.grid.patternFor(clue) === word
                                        }
                                        guessIncorrect={wordle.guessInvalid}
                                    />
                                    <Hidden><NumberSection>
                                        <div>{clue.number}.</div>
                                        <div>{directionSymbol}</div>
                                        </NumberSection></Hidden>
                                </ClueDiv>
                            </ClueSectionItem>
                        })
                    }
                </ClueSection>
            </TopSection>
            <Keyboard 
                keyPress={pressedKey} 
                letterColors={
                    crosswordle.keyColors(LEXICON_PERMISSIVE)
                } 
                theme={theme}
                showCrossInfo={showCrossInfo}
                swapSpecialKeys={swapSpecialKeys}
            />
        </Body>
        : <Endgame 
            key={crosswordle.crossword.id}
            grid={crosswordle.grid} 
            wordles={crosswordle.wordles} 
            result={crosswordle.gameState === GameState.Win} 
            crossword={crosswordle.crossword}
            lexicon={LEXICON_PERMISSIVE}
            maxGuesses={crosswordle.maxGuesses}
            crosswordleNumber={crosswordleNumber}
            theme={theme}
            startNewPracticeGame={startNewPracticeGame}
            practiceNumber={practiceNumber}
            playTime={crosswordle.playTime}
        />
    );
}

const Body = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media only screen and (max-width: 1200px) { 
        justify-content: space-between;
        height: calc(100% - 50px);
    }
`;

const TopSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ClueSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 600px;
    flex-wrap: wrap;
    align-content: center;
    width: 100%;
`;

const ClueSectionItem = styled.div<{
    active: boolean;
}>`
    margin: 0px 20px;

    ${({ active }) => 
        !active
            ? '@media only screen and (max-width: 1200px) { display: none; }'
            : ''
    }
`;

const ClueDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const Hidden = styled.div`
    visibility: hidden;
`;

const NumberSection = styled.div<{
    theme: Theme;
}>`
    width: 15px;
    ${({ theme }) => `color: ${theme.foreground}`}
`;

const CrosswordleNumber = styled.div<{
    theme: Theme;
}>`
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 12px;
    text-align: right;
    line-height: 20px;
    ${({ theme }) => `color: ${theme.foregroundMuted}`}
`;