import styled from "styled-components";
import { Wordle, WordleScore } from "../../core";
import { Lexicon } from "../../core";
import { buildArray } from "../../utils";
import { Theme } from "../themes";

interface WordleProps {
    word: string;
    guessCount: number;
    answers: string[];
    currentAnswer: string;
    highlighed: boolean;
    onClick?: () => void;
    lexicon: Lexicon;
    redacted?: boolean;
    theme: Theme;
    correctElsewhere?: boolean;
    guessIncorrect: boolean;
    lettersFoundHere?: boolean[];
}

export const WordleUI: React.FC<WordleProps> = ({ 
    word, 
    guessCount, 
    answers, 
    currentAnswer,
    highlighed,
    onClick,
    lexicon,
    redacted = false,
    correctElsewhere = false,
    theme,
    guessIncorrect,
    lettersFoundHere,
}) => {
    const solved = word === answers[answers.length - 1];
    return <WordleDiv onClick={() => onClick && onClick()}>
        {buildArray(guessCount, (rowNumber) => {
            const isCurrent = rowNumber === answers.length;
            const answer = isCurrent ? currentAnswer : answers[rowNumber];
            const score = 
                answer
                ? Wordle.score(word, answer, lexicon) 
                : undefined;
            return <WordleRow key={rowNumber}>
                {buildArray(word.length, (columnNumber) => {
                    const letter = answer?.charAt(columnNumber);
                    return <WordleCell 
                        key={columnNumber}
                        highlighted={highlighed}
                        solved={solved}
                        solvedElsewhere={correctElsewhere}
                        solvedHere={lettersFoundHere ? lettersFoundHere[columnNumber] : true}
                        theme={theme}
                        size={redacted ? "mini" : "normal"}
                        score={
                            (
                                !isCurrent 
                                && (
                                    score?.status === "win" 
                                    || score?.status === "lose"
                                )
                            )
                            ? score.scores[columnNumber] 
                            : isCurrent && guessIncorrect
                            ? WordleScore.Invalid
                            : undefined
                        }
                    >
                        {!redacted && letter}
                    </WordleCell>
                })}
            </WordleRow>
        })}
    </WordleDiv>;
}

const WordleDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const WordleRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const WordleCell = styled.div<{ 
    score: WordleScore | undefined, 
    highlighted: boolean,
    solved: boolean,
    solvedElsewhere: boolean,
    solvedHere: boolean,
    size: "mini" | "normal",
    theme: Theme;
}>`
    height: 30px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    &:not(:first-child) {
        border-left: 0;
    }
    ${({ score, highlighted, solved, size, theme, solvedElsewhere, solvedHere }) => 
        (score === WordleScore.Hit 
        ? `background-color: ${
            highlighted ? solvedHere ? theme.hit : theme.hitMuted : theme.hitMuted
        }; color: ${
            highlighted ? "white" : theme.hitMutedForeground
        };` 
        : score === WordleScore.NearMiss 
        ? `background-color: ${
            highlighted ? theme.nearMiss : theme.nearMissMuted
        }; color: white;` 
        : score === WordleScore.Miss 
        ? `background-color: ${
            highlighted ? theme.miss : theme.missMuted
        }; color: white;` 
        : score === WordleScore.Invalid 
        ? `background-color: ${theme.invalid}; color: white;` 
        : `background-color: ` + (
            solvedElsewhere
            ? theme.hitMuted 
            : highlighted 
            ? theme.emptyHighlight 
            : theme.empty
        ) + `; color: ${theme.emptyForeground};`) 
        + ( 
            size === "mini" 
            ? "width: 10px; height: 10px;" 
            : "width: 30px; height: 30px;"
        )
        + (
            `border: 1px solid ${theme.borderDark};`
        )
    }
`;