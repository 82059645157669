import { Layout, Lexicon, Position } from ".";
import { Crossword } from "./base";

const ALPHABET = "abcdefghijklmnopqrstuvwxyz".split("");

export function *fillCrossword(
    crossword: Crossword, 
    lexicon: Lexicon
): Generator<Crossword> {
    function *recurse(positions: Position[]): Generator<Crossword> {
        if (positions.length === 0) {
            const words = crossword.layout.getClues()
                .map((clue) => crossword.patternFor(clue));
            if (new Set(words).size === words.length) {
                yield crossword;
            }
            return;
        }
        const position = positions[0];
        const [clue1, clue2] = crossword.layout.cluesAt(position);
        for (const letter of ALPHABET) {
            crossword.set(position, letter);
            const pattern1 = crossword.patternFor(clue1);
            if  (lexicon.hasMatch(pattern1)) {
                const pattern2 = crossword.patternFor(clue2);
                if (lexicon.hasMatch(pattern2)) {
                    for (const out of recurse(positions.slice(1))) {
                        yield out;
                    }
                }
            }
            crossword.unset(position)
        }
    }
    for (const out of recurse(crossword.emptyPositions())) {
        yield out;
    }
}

export function generateCrosswords(
    layout: Layout, 
    lexicon: Lexicon, 
    startLetter: string
): Generator<Crossword> {
    const crossword = new Crossword(layout);
    crossword.set(crossword.emptyPositions()[0], startLetter);
    return fillCrossword(crossword, lexicon);
}