import { useEffect, useState } from "react";
import styled from "styled-components";
import { DictionaryEntry, getDefinition } from "../../core";
import { Theme } from "../themes";

const MISSING = Symbol("missing");

function useDefinition(word: string) {
    const [definition, setDefinition] = useState<DictionaryEntry[] | typeof MISSING>();

    useEffect(() => {
        getDefinition(word)
            .then(async (definitions) => {
                if (definitions === undefined) {
                    setDefinition(MISSING)
                }  else {
                    setDefinition(definitions)
                }
            })
            .catch(() => setDefinition(MISSING));
    }, [word]);

    return definition;
}

interface DefinitionProps {
    word: string;
    theme: Theme;
}

export const Definition: React.FC<DefinitionProps> =  ({ word, theme }) => {
    const definition = useDefinition(word);

    return <DefinitionDiv>
        { definition === undefined
            ? <div>Loading...</div>
            : definition === MISSING 
            ? <div>
                <div>
                    <b>
                        <NiceA 
                            href={`https://en.wiktionary.org/wiki/${word}`}
                            theme={theme}
                        >
                            { word }
                        </NiceA>
                    </b>
                </div>
                <DefinitionP>No definition available.</DefinitionP>
            </div>
            : <div>
                <div>
                    <b>
                        <NiceA 
                            href={`https://en.wiktionary.org/wiki/${word}`}
                            theme={theme}
                        >
                            { definition[0]?.word}
                        </NiceA>
                    </b>
                    { definition[0]?.phonetic &&
                            <Phonetic>
                                &nbsp;
                            {definition[0].phonetic}
                        </Phonetic>
                    }  
                </div>
                { definition
                    .filter((entry) => {
                        return  mapPartOfSpeech(entry.meanings[0].partOfSpeech);
                    })
                    .map((entry, i1) => <div key={i1}> 
                        { entry.meanings[0].definitions[0] &&
                            <DefinitionP>
                                { entry.meanings[0] &&
                                    <i>
                                        {mapPartOfSpeech(entry.meanings[0].partOfSpeech)}
                                    </i>
                                }  
                                &nbsp;
                                {entry.meanings[0].definitions[0]}
                            </DefinitionP>
                        } 
                    </div>)}
            </div> 
        }
    </DefinitionDiv>;
}

const DefinitionDiv = styled.div`
    flex-grow: 0;
    flex: 1;    
`;

const Phonetic = styled.span`
    color: #505050;
`;

const DefinitionP = styled.p`
    margin: 10px 0px 0px 0px;
`;

const NiceA = styled.a<{
    theme: Theme
}>`
    ${({ theme }) => `color: ${theme.hit}`}
`;

function mapPartOfSpeech(pos: string) {
    return ({
        "noun": "n.",
        "verb": "v.",
        "adj": "adj.",
        "adv": "adv.",
        "pron": "pron.",
        "prep": "prep.",
        "conj": "conj.",
        "intj": "intj.",
        "article": "art.",
        "postp": 'prep.',
        "det": "det.",
        "symbol": "sym.",
        "num": "num.", 
        "particle": "p", 
        "prefix": "ptcl.",
    })[pos];
}