import styled from "styled-components";
import { KeyColor, KeyColorBase } from "../../core";
import { stripes } from "../../utils";
import { Theme } from "../themes";

function solid(color: string) {
    return `background-color: ${color};`;
}

export const KeyUI = styled.div<{ 
    colorCode: KeyColor | undefined
    wide: boolean,
    theme: Theme;
    showCrossInfo: boolean;
}>`
    border-radius: 3px;
    padding: 2px;
    text-transform: uppercase;
    height: min(calc(1.5 * ((100vw - 10px - 50px) / 11)), 2.25em);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    user-select: none;
    ${({ colorCode, wide, theme, showCrossInfo }) => 
        (colorCode?.base === KeyColorBase.Hit 
        ? `color: white;` + (showCrossInfo && colorCode.crossInvalid 
            ? stripes(theme.hit, theme.hitMissMix) 
            : solid(theme.hit)
        )
        : colorCode?.base === KeyColorBase.Miss
        ? `background-color: ${theme.miss}; color: ${theme.missForeground};`
        : colorCode?.base === KeyColorBase.NearMiss
        ? `color: white;` + (showCrossInfo && colorCode.crossInvalid 
            ? stripes(theme.nearMiss, theme.nearMissMissMix) 
            : solid(theme.nearMiss)
        )
        : `color: ${theme.emptyForeground};` + (showCrossInfo && colorCode?.crossInvalid 
            ? stripes(theme.emptyKey, theme.missMuted) 
            : solid(theme.emptyKey)
        ))
        + (
            wide 
            ? "width: min(calc(1.5 * (100vw - 10px - 50px) / 11), 2.25em); text-transform: none;" 
            : "width: min(calc((100vw - 10px - 50px) / 11), 1.5em)"
        )
    }
`;