import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import domToImage from "dom-to-image";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Statistics } from "../../core";
import { buildArray, getCrosswordleNumber, tomorrowLocal } from "../../utils";
import { Countdown } from "../Countdown";
import { Theme } from "../themes";
import { 
    faArrowUpFromBracket,
} from '@fortawesome/free-solid-svg-icons'
import { trackShareStatistics } from "../../analytics";

interface StatisticsProps {
    statistics: Statistics;
    theme: Theme;
}

const DOM_SCALE = 6;

export const StatisticsUI: React.FC<StatisticsProps> = ({ statistics, theme }) => {
    const statsRef = useRef<HTMLDivElement>(null);
    const [statsBlob, setStatsBlob] = useState<Blob>();

    const minScore = 6;
    const bars = buildArray(35 / Statistics.BUCKET_SIZE, (index) => {
        return statistics.scoreDistribution.get(
            (index * Statistics.BUCKET_SIZE) + minScore
        ) ?? 0;
    });
    const lastScore = statistics.lastScore;
    const currentPuzzleNumber = getCrosswordleNumber();
    const highlightBar = lastScore?.crosswordleNumber === currentPuzzleNumber
        ? Math.max(6, Math.floor(
            (lastScore.guessCount - 1) / Statistics.BUCKET_SIZE
        ) * Statistics.BUCKET_SIZE + 1)
        : undefined;

    const maxCount = Math.max(...statistics.scoreDistribution.values());
    
    useEffect(() => {
        setTimeout(() => {
            const node = statsRef.current;
            if (node) {
                const options = {
                    width: node.clientWidth * DOM_SCALE,
                    height: node.clientHeight * DOM_SCALE,
                    bgcolor: theme.background,
                    style: {
                        transform: `scale(${DOM_SCALE})`,
                        transformOrigin: 'top left'
                    }
                };
                domToImage.toBlob(node, options).then(setStatsBlob);
            }
        });
    }, [statsRef, theme]);

    const shareData = statsBlob ? {
        files: [
            new File([statsBlob], "crosswordle_statistics.png", {
                type: statsBlob.type,
            }),
        ],
        title: "Crosswordle Statistics",
        url: "https://crosswordle.xyz"
    } : undefined;

    const canShare = () => {
        try {
            return navigator.canShare !== undefined && shareData && navigator.canShare(shareData);
        } catch (error) {
            return false;
        }
    }

    const shareEnabled = canShare();

    const onShare = async () => {
        try {
            if (navigator.canShare(shareData)) {
                navigator.share(shareData)
                    .then(() => {})
                    .catch((error) => {
                        console.log(error);
                    });
                trackShareStatistics({});
            }
        } catch (error) {
            console.log(error);
        }
    }

    return <StatisticsDiv theme={theme}>
        <InnerStatsDiv ref={statsRef}>
            <KPIBar>
                <KPI>
                    <KPINumber>{statistics.playCount}</KPINumber>
                    <KPILabel theme={theme}>Games Played</KPILabel>
                </KPI>
                <KPI>
                    <KPINumber>{
                        statistics.winPercentage
                        ? (statistics.winPercentage * 100).toFixed(0) + "%"
                        : "–"
                    }</KPINumber>
                    <KPILabel theme={theme}>Win Percent</KPILabel>
                </KPI>
                <KPI>
                    <KPINumber>{statistics.currentStreak}</KPINumber>
                    <KPILabel theme={theme}>Current Streak</KPILabel>
                </KPI>
                <KPI>
                    <KPINumber>{statistics.longestStreak}</KPINumber>
                    <KPILabel theme={theme}>Best Streak</KPILabel>
                </KPI>
            </KPIBar>
            <ScoreDistDiv>
                Score Distribution
                <Graph>
                    { bars.map((count, index) => {
                        const bucketMin = (index * Statistics.BUCKET_SIZE) + minScore;
                        return <GraphRow key={index}>
                            <GraphLabel>{ (bucketMin) - (index === 0 ? 1 : 0) } - { 
                                bucketMin + Statistics.BUCKET_SIZE - 1
                            }</GraphLabel>
                            <GraphBar 
                                width={count / (maxCount || 1)}
                                theme={theme}
                                highlighted={bucketMin === highlightBar}
                            >
                                {count}
                            </GraphBar>
                        </GraphRow>
                    })}
                    
                </Graph>
            </ScoreDistDiv>
        </InnerStatsDiv>
        <ShareDiv>
            <NextUp>
                <div>Next Crosswordle</div>
                <Big><Countdown end={tomorrowLocal()} /></Big>
            </NextUp>
            { shareEnabled && <div onClick={onShare}>Share <IconButton 
                icon={faArrowUpFromBracket} 
                color={theme.hit} 
                
                size="lg"
            /></div> }
        </ShareDiv>  
    </StatisticsDiv>;
}

const StatisticsDiv = styled.div<{
    theme: Theme;
}>`
    margin-top: 50px;
    ${({ theme }) => `color: ${theme.foreground}`}
`;

const KPIBar = styled.div`
    display: flex;
    flex-direction: row;
`;

const KPI = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90px;
`

const KPINumber = styled.div`
    font-size: 20px;
    margin-bottom: 5px;
`;

const KPILabel = styled.div<{
    theme: Theme;
}>`
    font-size: 12px;
    ${({ theme }) => `color: ${theme.foregroundMuted}`}
`;

const ScoreDistDiv = styled.div`
    margin-top: 20px;
`;

const Graph = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px;
`;

const GraphRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;

const GraphBar = styled.div<{ 
    width: number,
    theme: Theme,
    highlighted: boolean,
}>`
    ${({ width, theme, highlighted }) => 
        `width: ${width * 80}%;`
        + `background-color: ${highlighted ? theme.hit : theme.miss};`
    }
    min-width: 10px;
    max-width: 80%;
    color: white;
    padding: 4px;
    margin-left: 5px;
    text-align: right;
    font-weight: bold;
`;

const GraphLabel = styled.div`
    width: 55px;
    text-align: center;
`;

const NextUp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Big = styled.div`
    font-size: 30px;
`;

const IconButton = styled(FontAwesomeIcon)`
    margin-left: 10px;
    cursor: pointer;
`;

const ShareDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const InnerStatsDiv = styled.div`
    padding: 20px;
`;